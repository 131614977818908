import React from 'react';
import './App.css';
import Navbar from "./Components/Navbar";
import RouteSwitch from "./Components/RouteSwitch";
import { SocialIcon } from 'react-social-icons';

class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      auth: false
    };

    this.updateAuth = this.updateAuth.bind(this);
    this.removeAuth = this.removeAuth.bind(this);
    this.checkAuthData = this.checkAuthData.bind(this);
  }

  componentDidMount() {
window.scrollTo(0, 0);
    this.checkAuthData();
  }

  async checkAuthData() {
    let authData = getCookieData();

    if(authData.username && authData.token) { 
      let authCheck = await checkAuth(authData.username, authData.token);

      if(authCheck.length > 0) {
        this.setState({auth: true})
      }
      else {
        this.setState({auth: false})
      }
    }
    else {
      this.setState({auth: false})
    }
  }

  updateAuth() {
    this.setState({
      auth: true
    })
  }

  removeAuth() {
    this.setState({
      auth: false
    })
  }

  render() {
    return (
      <div>
        <Navbar auth={this.state.auth}  updateAuth={this.updateAuth} removeAuth={this.removeAuth}/>
        <RouteSwitch auth={this.state.auth} updateAuth={this.updateAuth} removeAuth={this.removeAuth}/>

      <div className="footer">
        <p style={{color: "white"}}>The Rotary Club of St. Marys <br/>
          22 St. Andrews St. N., St. Marys, ON N4X 1C5 <br/>
          Mailing address: 12-20 Huron St. N., St. Marys, ON N4X 1C5
        </p>
          
          <div className="socalIcons">
            <SocialIcon style={{margin: "20px", width: "30px", height: "30px"}} url="https://www.facebook.com/rotarystmarys/" />
            <SocialIcon style={{margin: "20px", width: "30px", height: "30px"}} url="https://rotarystmarys.ca/" />
            <SocialIcon style={{margin: "20px", width: "30px", height: "30px"}} url="https://www.instagram.com/rotarystmarys/?fbclid=IwAR16QSFXnOWSoBYMp091fqiTpEqSg6qd7-7kEt2DRdyIgtzEvOPHLEEHC84" />
          <br/>
          <p style={{color: "white", padding: "0px", margin: "2px", fontSize: "12px"}}>Owned and Operated by the St Marys Rotary Club 2022</p>
          </div>
        </div>
      </div>
    );
  }
}


async function checkAuth(username, token) {
  let myJson = {};
  myJson.username = username;
  myJson.token = token;
  let data = {};

  await (async () => {
    const rawResponse = await fetch('/api/v1/user/auth', {
      method: 'post',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(myJson)
    });
    data = await rawResponse.json();
  })();

  return data;
}

// loads the date object from the savedDate cookie
// returns the date as a string or null if it does not exist
// can be converted back to date with new Date(dateString)
function getCookieData() {
  const cookieObj = Object.fromEntries(
    document.cookie.split("; ").map(c => {
      const [key, ...v] = c.split("=");
      return [key, v.join("=")];
    })
  );
  return cookieObj || [];
}

export default App;