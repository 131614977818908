import React from 'react';
import TextField from '@material-ui/core/TextField';
import history from './history';
import Checkbox from '@material-ui/core/Checkbox';
import Tooltip from '@material-ui/core/Tooltip';
import Icon from '@material-ui/core/Icon';
import { Link } from "react-router-dom";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import PrintIcon from '@material-ui/icons/Print';
import EditIcon from '@material-ui/icons/Edit';
import LibraryAddCheckIcon from '@material-ui/icons/LibraryAddCheck';
import { saveAs } from 'file-saver'
import axios from 'axios';

class AdminRaffleDetails extends React.Component {
  constructor(props) {
    super(props);

    let pathname = props.location.pathname;
    let id = pathname.split("/")[2];

    this.state = {
      id: id,
      name: "",
      description: "", 
      start: "",
      end: "",
      tickets: 0,
      over_nineteen: false,
      ticket_price: 0.0,
      full_ticket_price: 0.0,
      value: 0,
      reserved_tickets: 0,
      location: "",
      pre_post_draw: false,
      pre_post_draw_date: "",
      pre_post_draw_value: 0.0,
      pre_post_draw_description: "",
      pre_post_draw_location: "",
      loading: true,
      ticketInfo: [],
      reservedTicketInfo: [],
      selectedTickets: [],
      selectedReserved: [],
      allTickets: [],
      editable: false,
      deletable: false,
    }

      this.getRaffleData = this.getRaffleData.bind(this);
      this.deleteRaffle = this.deleteRaffle.bind(this);
      this.stopRaffle = this.stopRaffle.bind(this);
      this.ticketData = this.ticketData.bind(this);
      this.goBack = this.goBack.bind(this);
      this.selectReserved = this.selectReserved.bind(this);
      this.selectTicket = this.selectTicket.bind(this);
      this.printSelectedTickets = this.printSelectedTickets.bind(this);
      this.printPurchasedTickets = this.printPurchasedTickets.bind(this);
      this.printReservedTickets = this.printReservedTickets.bind(this);
      this.printSingle = this.printSingle.bind(this);
      this.printAllTickets = this.printAllTickets.bind(this);
      this.printAllReservedTickets = this.printAllReservedTickets.bind(this);
  }

  componentDidMount () {
    this.getRaffleData();
    window.scrollTo(0, 0);
  }

  twoDigits(date) {
    if(0 <= date && date < 10) return "0" + date.toString();
    if(-10 < date && date < 0) return "-0" + (-1*date).toString();
    return date.toString();
  }

  async getRaffleData() {
    let dateData = await getDrawDateDetails(this.state.id);

    if(dateData.length === 0) {
      this.setState({editable: true, deletable: true})
    }

    let endDateData = await getDrawEndDateDetails(this.state.id);

    if(endDateData.length === 0) {
      this.setState({editable: false, deletable: true})
    }
      
    let authData = getCookieData();

      if(authData.username && authData.token) { 
        let authCheck = checkAuth(authData.username, authData.token);

        if(authCheck.length === 0) {
          await history.push("/login");
          window.scrollTo(0, 0);
          window.location.reload();
          this.props.changeRemoveAuth();
        }
        else {
          let myRaffles = await getRaffleDetails(this.state.id);
          //console.log(myRaffles)

          if(myRaffles.length > 0) {
            let myStart = ""
            if(myRaffles[0].start !== null) {
              myStart = myRaffles[0].start.replace(" ", "T");
              let newDate = new Date(myStart)
              myStart = newDate.getFullYear() + "-" + this.twoDigits(1 + newDate.getMonth()) + "-" + this.twoDigits(newDate.getDate()) + " " + this.twoDigits(newDate.getHours()) + ":" + this.twoDigits(newDate.getMinutes()) + ":00";
              myStart = myStart.replace(" ", "T");
              this.setState({start: myStart});
            }
            let myEnd = "";
            if(myRaffles[0].end !== null) {
              myEnd = myRaffles[0].end.replace(" ", "T");
              let newDate = new Date(myEnd)

              myEnd = newDate.getFullYear() + "-" + this.twoDigits(1 + newDate.getMonth()) + "-" + this.twoDigits(newDate.getDate()) + " " + this.twoDigits(newDate.getHours()) + ":" + this.twoDigits(newDate.getMinutes()) + ":00";
              myEnd = myEnd.replace(" ", "T");
              this.setState({end: myEnd});
            }
            let myPrePost = "";
            if(myRaffles[0].pre_post_draw_date !== null) {
              myPrePost = myRaffles[0].pre_post_draw_date.replace(" ", "T");
              let newDate = new Date(myPrePost)

              myPrePost = newDate.getFullYear() + "-" + this.twoDigits(1 + newDate.getMonth()) + "-" + this.twoDigits(newDate.getDate()) + " " + this.twoDigits(newDate.getHours()) + ":" + this.twoDigits(newDate.getMinutes()) + ":00";
              myPrePost = myPrePost.replace(" ", "T");
              this.setState({pre_post_draw_date: myPrePost});
            }

              this.setState({name: myRaffles[0].name});
              this.setState({description: myRaffles[0].description});
              this.setState({liscence: myRaffles[0].liscence});
              this.setState({tickets: myRaffles[0].tickets});
              this.setState({value: myRaffles[0].value});
              this.setState({ticket_price: myRaffles[0].ticket_price});
              this.setState({full_ticket_price: myRaffles[0].full_ticket_price});

              if(myRaffles[0].over_nineteen === 1) {
                this.setState({over_nineteen: true});
              }

              this.setState({reserved_tickets: myRaffles[0].reserved_tickets});
              this.setState({location: myRaffles[0].location});
              
              if(myRaffles[0].pre_post_draw === 1) {
                this.setState({pre_post_draw: true});
              }

              this.setState({pre_post_draw_description: myRaffles[0].pre_post_draw_description});
              this.setState({pre_post_draw_location: myRaffles[0].pre_post_draw_location});
              this.setState({pre_post_draw_value: myRaffles[0].pre_post_draw_value});

              let allTickets = await getAllTicketDetails(this.state.id);
              let myTickets = await getTicketDetails(this.state.id);
              let myReservedTickets = await getReservedTicketDetails(this.state.id);

              //console.log(allTickets)

              if(allTickets.length > 0) {
                this.setState({allTickets: allTickets});
              }

              if(myTickets.length > 0) {
                this.setState({ticketInfo: myTickets});
              }

              if(myReservedTickets.length > 0) {
                this.setState({reservedTicketInfo: myReservedTickets});
              }
          }
          else {
            await history.push("/admin");
            window.scrollTo(0, 0);
            window.location.reload();
          }

          this.props.changeAuth();
        }
      }
      else {
        await history.push("/login");
        window.scrollTo(0, 0);
        window.location.reload();
        this.props.changeRemoveAuth();
      }
    this.setState({loading: false})
    window.scrollTo(0, 0);
  }

  async stopRaffle() {
      let myJson = {};
      myJson.id = this.state.id;
      
      await updateRaffleDate(myJson);

      window.scrollTo(0, 0);
      window.location.reload();
  }

  async deleteRaffle() {
    let myJson = {};
    myJson.id = this.state.id;
    this.setState({loading: true})
    let response = await archiveRaffle(myJson);

    //console.log(response)

    if(response.warningStatus === 0) {
      await history.push("/admin");
      window.scrollTo(0, 0);
      window.location.reload();
      this.setState({loading: false})
    }
    else {
      this.setState({loading: false})
      //console.log(response.error);
    }
  }

  async goBack() {
    await history.push("/admin");
    window.scrollTo(0, 0);
    window.location.reload();
  }

  ticketData() {
    return (
      <TableBody>
        {this.state.ticketInfo.map((ticket) => (
          <TableRow key={ticket.id}>
            <TableCell style={{width: "80px"}} >
              <Checkbox
                color="primary"
                onChange={() => this.selectTicket(ticket)}
              />
            </TableCell> 
            <TableCell>
                {ticket.ticket_number}
            </TableCell>
            <TableCell>
              {ticket.first_name} {ticket.last_name}
            </TableCell>
            <TableCell >
              {ticket.email}
            </TableCell>
            <TableCell >
              <Tooltip title="Print Ticket">
                <PrintIcon className="print-button-icon" onClick={() => this.printSingle(ticket)}/>
              </Tooltip>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    )
  }

  selectReserved(ticket) {
    let selected = false;

    for(let i = 0; i < this.state.selectedReserved.length; i++) {
      if(this.state.selectedReserved[i].id === ticket.id) {
        selected = true;
        let newState = this.state.selectedReserved;
        newState.splice(i, 1);
        this.setState({selectedReserved: newState});
      }
    }

    if(selected === false) {
      let newState = this.state.selectedReserved;
      newState[newState.length] = ticket;
      this.setState({selectedReserved: newState});
    }
  }

  selectTicket(ticket) {
    let selected = false;

    for(let i = 0; i < this.state.selectedTickets.length; i++) {
      if(this.state.selectedTickets[i].id === ticket.id) {
        selected = true;
        let newState = this.state.selectedTickets;
        newState.splice(i, 1);
        this.setState({selectedTickets: newState});
      }
    }

    if(selected === false) {
      let newState = this.state.selectedTickets;
      newState[newState.length] = ticket;
      this.setState({selectedTickets: newState});
    }
  }

  async printReservedTickets() {
    let result = await printTicketsCall(this.state.selectReserved, this.state.liscence);

    if(result.error === "Success") {
      printTickets();
      setTimeout( () => {
        this.setState({loading: false});
      }, 2500);
    }
    else {
      this.setState({loading: false});
    }
  }

  async printSelectedTickets() {
    this.setState({loading: true});
    let result = await printTicketsCall(this.state.selectedTickets, this.state.liscence);

    if(result.error === "Success") {
      await printTickets();

      setTimeout( () => {
        this.setState({loading: false});
      }, 2500);
    }
    else {
      this.setState({loading: false});
    }
  }

  async printPurchasedTickets() {
    this.setState({loading: true});
    let result = await printTicketsCall(this.state.ticketInfo, this.state.liscence);

    if(result.error === "Success") {
      await printTickets();
      setTimeout( () => {
        this.setState({loading: false});
      }, 2500);
    }
    else {
      this.setState({loading: false});
    }
  }

  async printAllReservedTickets() {
    this.setState({loading: true});
    let result = await printTicketsCall(this.state.reservedTicketInfo, this.state.liscence);

    if(result.error === "Success") {
      await printTickets();
      setTimeout( () => {
        this.setState({loading: false});
      }, 2500);
    }
    else {
      this.setState({loading: false});
    }
  }

  async printAllTickets() {
    this.setState({loading: true});
    let result = await printTicketsCall(this.state.allTickets, this.state.liscence);

    if(result.error === "Success") {
      await printTickets();
      setTimeout( () => {
        this.setState({loading: false});
      }, 2500);
    }
    else {
      this.setState({loading: false});
    }
  }

  async printSingle(ticket) {
    this.setState({loading: true});
    let tickets = [];
    tickets[0] = ticket;

    let result = await printTicketsCall(tickets, this.state.liscence);

    if(result.error === "Success") {
      await printTickets();
      setTimeout( () => {
        this.setState({loading: false});
      }, 2500);
    }
    else {
      this.setState({loading: false});
    }
  }

  reservedTicketData() {
    return (
      <TableBody>
        {this.state.reservedTicketInfo.map((ticket) => (
          <TableRow key={ticket.id}>
            <TableCell style={{width: "80px"}} >
              <Checkbox
                color="primary"
                onChange={() => this.selectReserved(ticket)}
              />
              </TableCell> 
              <TableCell>{ticket.ticket_number}</TableCell>
              <TableCell>
                {ticket.first_name} {ticket.last_name}
              </TableCell>
              <TableCell>
                {ticket.email}
              </TableCell>
              <TableCell style={{width: "80px"}}>
                 <Tooltip title="Print Ticket">
                  <PrintIcon className="print-button-icon" onClick={() => this.printSingle(ticket)}/>
                </Tooltip>
              </TableCell>
              <TableCell style={{width: "80px"}}>
                <Tooltip title="Edit Customer Info">
                  <Link to={"/editticket/" + ticket.id}>
                      <EditIcon className="print-button-icon" style={{ fontSize: 40 }} />
                  </Link>
                </Tooltip>
              </TableCell>
          </TableRow>
        ))}
      </TableBody>
    )
  }

  render() {
    return (
      <div className="tabContent">
        {this.state.loading === true &&
          <div>
            <br/>
            <br/>
            <div className="loader"></div>
            <br/>
            <br/>
          </div>
        }

          {this.state.loading === false &&
          <div style={{margin: "20px", marginTop: "40px", marginBottom: "100px", display: "inline"}}>
            <div style={{float: "right"}}>
              {this.state.editable === true &&
                <Tooltip title="Edit Lottery">
                  <Link to={"/editlottery/" + this.state.id}>
                      <EditIcon className="print-button-icon" style={{ fontSize: 40 }} />
                  </Link>
                </Tooltip>
              }
              {this.state.deletable === false && this.state.editable === false &&
                <Tooltip title="Stop Lottery">
                    <Icon className="delete-button-icon" style={{ fontSize: 40 }} onClick={this.stopRaffle}>dangerous</Icon>
                </Tooltip>
              }
              {this.state.deletable === true &&
                <Tooltip title="Delete Lottery">
                    <Icon className="delete-button-icon" style={{ fontSize: 40 }} onClick={this.deleteRaffle}>delete</Icon>
                </Tooltip>
              }
              </div>
            <div>
          <h3>{this.state.name}</h3>
          <br/><br/>
          <div className="all-items">
            <div className="input-item">
                <p >Name:</p>
                <TextField className="input-item-field" id="name" variant="outlined" disabled value={this.state.name}/>
            </div><br/>
            <div className="input-item">
                <p >Description:</p>
                <TextField className="input-item-field" id="description" variant="outlined" multiline rowsMax={6} disabled value={this.state.description}/>
            </div>
            <br/>
            <br/>
            <br/>
            <div className="input-item">
              <p >Main Draw Location:</p>
              <TextField className="input-item-field" id="location" variant="outlined" disabled value={this.state.location}/>
            </div><br/>
            <div className="input-item">
                <p >Start:</p>
                <TextField
                className="input-item-field"
                variant="outlined"
                id="start"
                type="datetime-local"
                disabled
                value={this.state.start}
                />
            </div><br/>
            <div className="input-item">
                <p >End:</p>
                <TextField
                className="input-item-field"
                variant="outlined"
                id="end"
                type="datetime-local"
                disabled
                value={this.state.end}
                />
            </div><br/>
            <div className="input-item">
              <p>Liscence Number:</p>
              <TextField className="input-item-field" id="liscence" variant="outlined" disabled value={this.state.liscence}/>
            </div><br/>
            <div className="input-item">
                <p >Number of Tickets:</p>
                <TextField className="input-item-field" id="tickets" variant="outlined" type="number"  disabled value={this.state.tickets}/> 
            </div><br/>
            <div className="input-item">
                <p >Must be 19+:</p>
                <Checkbox
                className="input-item-field"
                checked={this.state.over_nineteen}
                disabled
                id="over_nineteen"
                color="primary"
                />
            </div>
            <br/>
            <p>Note: all customers must be 18+ by default. Only check this option if they must be 19+ to purchase.</p><br/>
            <div className="input-item">
                <p >Ticket Price:</p>
                <TextField className="input-item-field" id="ticket_price" variant="outlined" type="number"  disabled value={this.state.ticket_price}/> 
            </div><br/>
            <div className="input-item">
                <p >Full Ticket Price:</p>
                <TextField className="input-item-field" id="full_ticket_price" variant="outlined" type="number"  disabled value={this.state.full_ticket_price}/> 
            </div><br/>
            <div className="input-item">
                <p>Main Draw Value:</p>
                <TextField className="input-item-field" id="value" variant="outlined" type="number"  disabled value={this.state.value}/> 
            </div><br/>
            <div className="input-item">
                <p >Number of Reserved Tickets:</p>
                <TextField className="input-item-field" id="reserved_tickets" variant="outlined" type="number" disabled value={this.state.reserved_tickets}/> 
            </div>
          </div><br/>
          <div className="input-item">
            <p >Has Pre/Post Draw:</p>
              <Checkbox
              className="input-item-field"
              checked={this.state.pre_post_draw}
              disabled
              id="pre_post_draw"
              color="primary"
            />
          </div><br/>

          {this.state.pre_post_draw === true &&
            <div>
              <div className="input-item">
                <p >Pre/Post Draw Description:</p>
                <TextField className="input-item-field" id="pre_post_draw_description" variant="outlined" multiline rowsMax={6} disabled value={this.state.pre_post_draw_description}/>
              </div><br/>
              <div className="input-item">
                <p >Pre/Post Draw Date:</p>
                <TextField
                  className="input-item-field"
                  variant="outlined"
                  id="pre_post_draw_date"
                  type="datetime-local"
                  disabled
                  value={this.state.pre_post_draw_date}
                />
            </div><br/>
            <div className="input-item">
              <p >Pre/Post Draw Location:</p>
              <TextField className="input-item-field" id="pre_post_draw_location" variant="outlined" disabled value={this.state.pre_post_draw_location}/>
            </div> <br/>
            <div className="input-item">
                <p>Pre/Post Draw Value:</p>
                <TextField className="input-item-field" id="pre_post_draw_value" variant="outlined" type="number" disabled value={this.state.pre_post_draw_value}/> 
            </div><br/>
          </div>
          }
        <br/><br/>
        <h3>Tickets</h3><br/>

        <div style={{float: "right", marginTop: "20px", marginRight: "200px"}}>
            <Tooltip style={{float: "right"}} title="Print Purchased Tickets">
              <PrintIcon className="print-button-icon" onClick={this.printPurchasedTickets}/>
            </Tooltip>
            <Tooltip title="Print Selected Purchased Tickets">
                <LibraryAddCheckIcon className="print-button-icon" style={{ fontSize: 40 }} onClick={this.printSelectedTickets} />
            </Tooltip>
          </div>
        <p style={{textAlign: "left", marginLeft: "100px"}}>Purchased Tickets:</p><br/><br/>
        <div style={{maxHeight: "600px", overflowY: "scroll", maxWidth: "80%", margin: "auto"}}>
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                    <TableCell>
                      Select
                    </TableCell>
                      <TableCell style={{marginLeft: "-10px"}}>Number</TableCell>
                      <TableCell>Ticket</TableCell>
                      <TableCell>Customer</TableCell>
                      <TableCell>Email</TableCell>
                      <TableCell>Print</TableCell>
                    </TableRow>
                  </TableHead>
                  {this.ticketData()}
                </Table>
            </TableContainer>
          </div>
          <br/><br/>

          <div style={{float: "right", marginTop: "20px", marginRight: "200px"}}>
            <Tooltip style={{float: "right"}} title="Print Reserved Tickets">
              <PrintIcon className="print-button-icon"  onClick={this.printAllReservedTickets} />
            </Tooltip>
            <Tooltip title="Print Selected Reserved Tickets">
                <LibraryAddCheckIcon className="print-button-icon" style={{ fontSize: 40 }} onClick={this.printReservedTickets}/>
            </Tooltip>
          </div>
          <p style={{textAlign: "left", marginLeft: "100px"}}>Reserved Tickets:</p> <br/><br/>
          <div style={{maxHeight: "600px", overflowY: "scroll", maxWidth: "80%", margin: "auto"}}>
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                    <TableCell>
                      Select
                  </TableCell>
                      <TableCell style={{marginLeft: "-10px"}}>Number</TableCell>
                      <TableCell>Customer</TableCell>
                      <TableCell>Email</TableCell>
                      <TableCell>Print</TableCell>
                      <TableCell>Edit</TableCell>
                    </TableRow>
                  </TableHead>
                  {this.reservedTicketData()}
                </Table>
            </TableContainer>
            </div>
            <br/><br/>

            <button className="cancel-button" onClick={this.goBack}>Go Back</button>
            <button className="raffle-button" onClick={this.printAllTickets} >Print All</button>
            <br/>
            <br/>
            </div>
        </div>
        }
      </div>
    );
  }
}

async function updateRaffleDate(myJson) {
  let data = {};

  await (async () => {
    const rawResponse = await fetch('/api/v1/draws/updateDate', {
      method: 'post',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(myJson)
    });
    data = await rawResponse.json();
  })();

  //console.log(data);

  return data;
}

async function getRaffleDetails(raffleID) {
    let myJson = {};
    myJson.id = raffleID;
    let data = {};
  
    await (async () => {
      const rawResponse = await fetch('/api/v1/draws/get_details', {
        method: 'post',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(myJson)
      });
      data = await rawResponse.json();
    })();
  
    return data;
  }

  async function getAllTicketDetails(raffleID) {
    let myJson = {};
    myJson.id = raffleID;
    let data = {};
  
    await (async () => {
      const rawResponse = await fetch('/api/v1/tickets/get', {
        method: 'post',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(myJson)
      });
      data = await rawResponse.json();
    })();
    return data;
  }

  async function getTicketDetails(raffleID) {
    let myJson = {};
    myJson.id = raffleID;
    let data = {};
  
    await (async () => {
      const rawResponse = await fetch('/api/v1/tickets/get_purchased', {
        method: 'post',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(myJson)
      });
      data = await rawResponse.json();
    })();
    return data;
  }

  async function getReservedTicketDetails(raffleID) {
    let myJson = {};
    myJson.id = raffleID;
    let data = {};
  
    await (async () => {
      const rawResponse = await fetch('/api/v1/tickets/get_reserved', {
        method: 'post',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(myJson)
      });
      data = await rawResponse.json();
    })();
  
    return data;
  }

  async function printTicketsCall(ticketData, liscence_number) {
    //console.log(ticketData);

    let data = {};
    let myJson = {};
    myJson.tickets = ticketData;
    myJson.liscence_number = liscence_number
    
    await (async () => {
      const rawResponse = await fetch('/api/v1/pdf/create', {
        method: 'post',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(myJson)
      });
      data = await rawResponse.json();
    })();
  
    return data;
  }

  async function printTickets() {
    setTimeout(async function(){ 
      const { data } = await getTicketsPdf()
      const blob = new Blob([data], { type: 'application/pdf' })
      saveAs(blob, "tickets.pdf")
    }, 2000);
  }
  
  async function getTicketsPdf() {
    return axios.get('/api/v1/pdf/get', {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      responseType: 'arraybuffer'
    })
  }

async function archiveRaffle(myJson) {
  let data = {};

  await (async () => {
    const rawResponse = await fetch('/api/v1/draws/delete', {
      method: 'post',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(myJson)
    });
    data = await rawResponse.json();
  })();

  return data;
}

async function getDrawDateDetails(raffleID) {
  let myJson = {};
  myJson.id = raffleID;
  let data = {};

  await (async () => {
    const rawResponse = await fetch('/api/v1/draws/check_date', {
      method: 'post',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(myJson)
    });
    data = await rawResponse.json();
  })();

  //console.log(data);

  return data;
}

async function getDrawEndDateDetails(raffleID) {
  let myJson = {};
  myJson.id = raffleID;
  let data = {};

  await (async () => {
    const rawResponse = await fetch('/api/v1/draws/check_end_date', {
      method: 'post',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(myJson)
    });
    data = await rawResponse.json();
  })();

  //console.log(data);

  return data;
}

async function checkAuth(username, token) {
    let myJson = {};
    myJson.username = username;
    myJson.token = token;
    let data = {};
  
    await (async () => {
      const rawResponse = await fetch('/api/v1/user/auth', {
        method: 'post',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(myJson)
      });
      data = await rawResponse.json();
    })();
  
    return data;
  }
  
  // loads the date object from the savedDate cookie
  // returns the date as a string or null if it does not exist
  // can be converted back to date with new Date(dateString)
  function getCookieData() {
    const cookieObj = Object.fromEntries(
      document.cookie.split("; ").map(c => {
        const [key, ...v] = c.split("=");
        return [key, v.join("=")];
      })
    );
    return cookieObj || [];
  }

export default AdminRaffleDetails;