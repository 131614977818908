import React from 'react';
import TextField from '@material-ui/core/TextField';
import history from './history';
import Checkbox from '@material-ui/core/Checkbox';

class AddRaffleForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      name: "",
      description: "", 
      start: "",
      end: "",
      tickets: 0,
      over_nineteen: false,
      ticket_price: 0.0,
      full_ticket_price: 0.0,
      value: 0,
      reserved_tickets: 0,
      location: "",
      pre_post_draw: false,
      pre_post_draw_date: "",
      pre_post_draw_value: 0.0,
      pre_post_draw_description: "",
      pre_post_draw_location: "",
      loading: false,
      liscence: "",
      valid: false,
      errorLabel: "",
    }

    this.handleStateChange = this.handleStateChange.bind(this);
    this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
    this.addNewRaffle = this.addNewRaffle.bind(this);
    this.getRaffleData = this.getRaffleData.bind(this);
    this.validateInputs = this.validateInputs.bind(this);
    this.cancelAdd = this.cancelAdd.bind(this);
    this.handleCheckboxChangePost = this.handleCheckboxChangePost.bind(this);
    this.twoDigits = this.twoDigits.bind(this)
    this.dateFormated = this.dateFormated.bind(this)
  }

  componentDidMount(){
    window.scrollTo(0, 0);
    this.getRaffleData();
  }

  async cancelAdd() {
    await history.push("/admin");
    window.scrollTo(0, 0);
    window.location.reload();
  }

  async getRaffleData() {
    let authData = getCookieData();

    if(authData.username && authData.token) { 
      let authCheck = await checkAuth(authData.username, authData.token);

      if(authCheck.length === 0) {
        await history.push("/login");
        window.scrollTo(0, 0);
        window.location.reload();
        this.props.changeRemoveAuth();
      }
    }
    else {
      await history.push("/login");
      window.scrollTo(0, 0);
      window.location.reload();
      this.props.changeRemoveAuth();
    }
  }

  handleCheckboxChangePost = async(e) => {
    let newValue = !this.state.pre_post_draw;
    await this.setState({ [e.target.id]: newValue });
  }

  
  handleCheckboxChange = async(e) => {
    let newValue = !this.state.over_nineteen;
    await this.setState({ [e.target.id]: newValue });
  }

  handleStateChange = async(e) => {
    await this.setState({ [e.target.id]: e.target.value });
  }

  twoDigits(date) {
    if(0 <= date && date < 10) return "0" + date.toString();
    if(-10 < date && date < 0) return "-0" + (-1*date).toString();
    return date.toString();
  }

  dateFormated(dateString) {
      let myDate = new Date(dateString);
      
      let newDate = myDate.getFullYear() + "-" + this.twoDigits(1 + myDate.getMonth()) + "-" + this.twoDigits(myDate.getDate()) + " " + this.twoDigits(myDate.getHours()) + ":" + this.twoDigits(myDate.getMinutes()) + ":00";
      return newDate;
  }

  async addNewRaffle() {
    if(this.validateInputs() === true) {
      this.setState({loading: true});
        let myJson = {};
        myJson.name = this.state.name;
        myJson.description = this.state.description;
        
        let myStartDate = new Date(this.state.start)
        myJson.start = this.dateFormated(myStartDate)
        //console.log(myJson.start)

        let myEndDate = new Date(this.state.end)
        myJson.end = this.dateFormated(myEndDate)
        //console.log(myJson.end)

        myJson.liscence = this.state.liscence;
        myJson.tickets = parseFloat(this.state.tickets);
        myJson.over_nineteen = this.state.over_nineteen;
        myJson.ticket_price = parseFloat(this.state.ticket_price);
        myJson.full_ticket_price = parseFloat(this.state.full_ticket_price);
        myJson.value = parseFloat(this.state.value);
        myJson.reserved_tickets = parseFloat(this.state.reserved_tickets);
        myJson.location = this.state.location;

        //console.log(myJson.reserved_tickets)

        myJson.pre_post_draw = this.state.pre_post_draw;
        if(this.state.pre_post_draw_date !== "") {
          let myPostDate = new Date(this.state.pre_post_draw_date)
          myJson.pre_post_draw_date = this.dateFormated(myPostDate)
          //console.log(myJson.pre_post_draw_date)
        } else {
          myJson.pre_post_draw_date = "0000-00-00 00:00.000";
        }
        
        myJson.pre_post_draw_description = this.state.pre_post_draw_description;
        myJson.pre_post_draw_value = parseFloat(this.state.pre_post_draw_value);
        myJson.pre_post_draw_location = this.state.pre_post_draw_location;

        //console.log(myJson)

        let productResult = await addProduct(myJson);

        if(productResult.product !== undefined && productResult.full_price !== undefined && productResult.price !== undefined) {
          myJson.stripe_product = productResult.product.id;
          myJson.stripe_price = productResult.price.id;
          myJson.stripe_full_price = productResult.full_price.id;
          let response = await addRaffle(myJson);

          if(response.insertId !== undefined && response.insertId !== 0) {
            this.setState({loading: false});
              await history.push("/adminlottery/" + response.insertId);
              window.scrollTo(0, 0);
              window.location.reload();
          }
          else {
            this.setState({loading: false});
              //console.log(response.error);
          }
        }
        else {
          this.setState({loading: false});
            //console.log(productResult.error);
        }
    }
    else {
        //console.log("Invalid input");
    }
  }

  validateInputs() {
    let currentTime =  new Date()
    let dateString = this.dateFormated(currentTime.toString())
    dateString = dateString.replace(" ", "T")

    let name = this.state.name.replaceAll(/\s/g,'')
    let description = this.state.description.replaceAll(/\s/g,'')
    let liscence = this.state.liscence.replaceAll(/\s/g,'')
    let pre_post_draw_description = this.state.pre_post_draw_description.replaceAll(/\s/g,'')

    if(name.length <= 5) {
      this.setState({errorLabel: "You must enter a lottery name over 5 characters long"})
      this.setState({valid: false})
      return false;
    }
    else if(description.length <= 10) {
      this.setState({errorLabel: "You must enter a lottery description over 10 characters long"})
      this.setState({valid: false})
      return false;
    }
    else if(this.state.start === "") {
      this.setState({errorLabel: "You must enter a start lottery date"})
      this.setState({valid: false})
      return false;
    }
    else if((this.state.start + ":00") <= dateString) {
      this.setState({errorLabel: "The start lottery date must be after the current date"})
      this.setState({valid: false})
      return false;
    }
    else if(this.state.end === "") {
      this.setState({errorLabel: "You must enter an end lottery date"})
      this.setState({valid: false})
      return false;
    }
    else if((this.state.end + ":00") <= dateString) {
      this.setState({errorLabel: "The end lottery date must be after the current date"})
      this.setState({valid: false})
      return false;
    }
    else if((this.state.end + ":00") <= (this.state.start + ":00")) {
      this.setState({errorLabel: "The end lottery date must be after the start date"})
      this.setState({valid: false})
      return false;
    }
    else if(liscence.length === 0) {
      this.setState({errorLabel: "You must enter a liscence number"})
      this.setState({valid: false})
      return false;
    }
    else if(parseFloat(this.state.tickets) <= 0) {
      this.setState({errorLabel: "The amount of tickets must be greater than zero"})
      this.setState({valid: false})
      return false;
    }
    else if(parseFloat(this.state.ticket_price) <= 0) {
      this.setState({errorLabel: "The ticket price must be greater than zero"})
      this.setState({valid: false})
      return false;
    }
    else if(parseFloat(this.state.full_ticket_price) < parseFloat(this.state.ticket_price)) {
      this.setState({errorLabel: "The full ticket price must be greater than or equal to the regular ticket price"})
      this.setState({valid: false})
      return false;
    }
    else if(parseFloat(this.state.value) <= 0) {
      this.setState({errorLabel: "You must enter a estimated lottery value"})
      this.setState({valid: false})
      return false;
    }
    else if(parseFloat(this.state.reserved_tickets) < 0) {
      this.setState({errorLabel: "The reserved tickets must be greater than or equal to zero"})
      this.setState({valid: false})
      return false;
    }
    else if(!(parseFloat(this.state.reserved_tickets) < parseFloat(this.state.tickets))) {
      this.setState({errorLabel: "The reserved tickets must be less than the total amount of tickets"})
      this.setState({valid: false})
      return false;
    }

    if(this.state.pre_post_draw === true) {
      if(this.state.pre_post_draw_date === "") {
        this.setState({errorLabel: "The pre/post lottery date must be entered"})
      this.setState({valid: false})
      return false;
      }
      else if((this.state.pre_post_draw_date + ":00") <= dateString) {
        this.setState({errorLabel: "The pre/post lottery date must be after the current date"})
        this.setState({valid: false})
        return false;
      }
      else if((this.state.pre_post_draw_date + ":00") <= (this.state.start + ":00")) {
        this.setState({errorLabel: "The pre/post lottery date must be between the lottery start and end date"})
        this.setState({valid: false})
        return false;
      }
      else if((this.state.pre_post_draw_date + ":00") >= (this.state.end + ":00")) {
        this.setState({errorLabel: "The pre/post lottery date must be between the lottery start and end date"})
        this.setState({valid: false})
        return false;
      }
      else if(pre_post_draw_description <= 10) {
        this.setState({errorLabel: "You must enter a pre/post lottery description over 10 characters long"})
        this.setState({valid: false})
        return false;
      }
      else if(parseFloat(this.state.pre_post_draw_value) <= 0) {
        this.setState({errorLabel: "You must enter a pre/post lottery estimated value"})
        this.setState({valid: false})
        return false;
      }
    }

    return true;
  }

  render() {
    return (
      <div className="tabContent">
          <div style={{margin: "20px", marginTop: "40px", marginBottom: "100px", display: "inline"}}>
            <h3>New Lottery</h3>
            <br/><br/>
            {this.state.loading === true &&
              <div>
                <br/>
                <br/>
                <div className="loader"></div>
                <br/>
                <br/>
              </div>
            }

        {this.state.loading === false &&
          <div>
            <div className="all-items">
              <div className="input-item">
                  <p >Name:</p>
                  <TextField className="input-item-field" id="name" variant="outlined" required onChange={this.handleStateChange} value={this.state.name}/>
              </div><br/>
              <div className="input-item">
                  <p >Description:</p>
                  <TextField className="input-item-field" id="description" variant="outlined" multiline rowsMax={6} required onChange={this.handleStateChange} value={this.state.description}/>
              </div>
              <br/>
              <br/>
              <br/>
              <div className="input-item">
                <p >Main Draw Location:</p>
                <TextField className="input-item-field" id="location" variant="outlined" required onChange={this.handleStateChange} value={this.state.location}/>
              </div><br/>
              <div className="input-item">
                  <p >Start:</p>
                  <TextField
                  className="input-item-field"
                  variant="outlined"
                  id="start"
                  type="datetime-local"
                  onChange={this.handleStateChange}
                  value={this.state.start}
                  />
              </div><br/>
              <div className="input-item">
                  <p >End:</p>
                  <TextField
                  className="input-item-field"
                  variant="outlined"
                  id="end"
                  type="datetime-local"
                  onChange={this.handleStateChange}
                  value={this.state.end}
                  />
              </div><br/>
              <div className="input-item">
                  <p >Liscence Number:</p>
                  <TextField className="input-item-field" id="liscence" variant="outlined" multiline rowsMax={1} required onChange={this.handleStateChange} value={this.state.liscence}/>
              </div><br/>
              <div className="input-item">
                  <p >Number of Tickets:</p>
                  <TextField onWheel={(e) => e.target.blur()} className="input-item-field" id="tickets" variant="outlined" type="number" required onChange={this.handleStateChange} value={this.state.tickets}/> 
              </div><br/>
              <div className="input-item">
                  <p >Must be 19+ (defaults to 18+ if not selected):</p>
                  <Checkbox
                  className="input-item-field-checkbox"
                  checked={this.state.over_nineteen}
                  onChange={this.handleCheckboxChange}
                  id="over_nineteen"
                  color="primary"
                  />
              </div>
              <br/>
              <p>Note: all customers must be 18+ by default. Only check this option if they must be 19+ to purchase.</p><br/>
              <div className="input-item">
                  <p >Ticket Price:</p>
                  <TextField onWheel={(e) => e.target.blur()} className="input-item-field" id="ticket_price" variant="outlined" type="number" required onChange={this.handleStateChange} value={this.state.ticket_price}/> 
              </div><br/>
              <div className="input-item">
                  <p style={{marginTop: "-10px"}}>Ticket Price plus donation (the same as the ticket price if there is no donation):</p>
                  <TextField onWheel={(e) => e.target.blur()} className="input-item-field" id="full_ticket_price" variant="outlined" type="number" required onChange={this.handleStateChange} value={this.state.full_ticket_price}/> 
              </div><br/><br/><br/>
              <div className="input-item">
                  <p>Main Draw Value:</p>
                  <TextField onWheel={(e) => e.target.blur()} className="input-item-field" id="value" variant="outlined" type="number" required onChange={this.handleStateChange} value={this.state.value}/> 
              </div><br/>
              <div className="input-item">
                  <p >Number of Reserved Tickets:</p>
                  <TextField onWheel={(e) => e.target.blur()} className="input-item-field" id="reserved_tickets" variant="outlined" type="number" required onChange={this.handleStateChange} value={this.state.reserved_tickets}/> 
              </div>
            </div><br/>
            <div className="input-item">
              <p >Has Pre/Post Draw:</p>
                <Checkbox
                className="input-item-field-checkbox"
                checked={this.state.pre_post_draw}
                onChange={this.handleCheckboxChangePost}
                id="pre_post_draw"
                color="primary"
              />
            </div><br/>

            {this.state.pre_post_draw === true &&
              <div>
                <div className="input-item">
                  <p >Pre/Post Draw Description:</p>
                  <TextField className="input-item-field" id="pre_post_draw_description" variant="outlined" multiline rowsMax={6} required onChange={this.handleStateChange} value={this.state.pre_post_draw_description}/>
                </div><br/>
                <div className="input-item">
                  <p >Pre/Post Draw Date:</p>
                  <TextField
                    className="input-item-field"
                    variant="outlined"
                    id="pre_post_draw_date"
                    type="datetime-local"
                    onChange={this.handleStateChange}
                    value={this.state.pre_post_draw_date}
                  />
              </div><br/>
              <div className="input-item">
                <p >Pre/Post Draw Location:</p>
                <TextField className="input-item-field" id="pre_post_draw_location" variant="outlined" required onChange={this.handleStateChange} value={this.state.pre_post_draw_location}/>
              </div> <br/>
              <div className="input-item">
                  <p>Pre/Post Draw Value:</p>
                  <TextField onWheel={(e) => e.target.blur()} className="input-item-field" id="pre_post_draw_value" variant="outlined" type="number" required onChange={this.handleStateChange} value={this.state.pre_post_draw_value}/> 
              </div><br/>
            </div>
            }
            <br/><br/>
            <p >Creating this draw can take a few minutes depending on how many reserved tickets there are.</p>
            <br/>
            <button className="cancel-button" onClick={this.cancelAdd}>Cancel</button>
            <button className="raffle-button" onClick={this.addNewRaffle} disabled={this.state.loading}>Submit</button>
            <br/><br/><br/>
            <p className="errorLabel" >{ this.state.errorLabel }</p>
            <br/>
            <br/>
        </div>
      }
        </div>
      </div>
    );
  }
}

async function addProduct(myJson) {
  let data = {};

  await (async () => {
    const rawResponse = await fetch('/api/v1/stripe/create_product', {
      method: 'post',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(myJson)
    });
    data = await rawResponse.json();
  })();

  //console.log(data);

  return data;
}

async function addRaffle(myJson) {
  let data = {};

  await (async () => {
    const rawResponse = await fetch('/api/v1/draws/add', {
      method: 'post',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(myJson)
    });
    data = await rawResponse.json();
  })();

  //console.log(data);

  return data;
}


async function checkAuth(username, token) {
    let myJson = {};
    myJson.username = username;
    myJson.token = token;
    let data = {};
  
    await (async () => {
      const rawResponse = await fetch('/api/v1/user/auth', {
        method: 'post',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(myJson)
      });
      data = await rawResponse.json();
    })();
  
    //console.log(data);
  
    return data;
  }
  
  // loads the date object from the savedDate cookie
  // returns the date as a string or null if it does not exist
  // can be converted back to date with new Date(dateString)
  function getCookieData() {
    const cookieObj = Object.fromEntries(
      document.cookie.split("; ").map(c => {
        const [key, ...v] = c.split("=");
        return [key, v.join("=")];
      })
    );
    return cookieObj || [];
  }

export default AddRaffleForm;