import React from 'react';
import TextField from '@material-ui/core/TextField';
import { Link } from "react-router-dom";
import MediaQuery  from 'react-responsive';

class OrderQueuePage extends React.Component {
  constructor(props) {
    super(props);

    let pathname = props.location.pathname;
    let id = pathname.split("/")[2];

    this.state = {
      id: id,
      customer_id: "",
      stripe_customer_id: "", 
      card_id: "",
      stripe_price_id: "",
      stripe_product_id: "",
      tickets: 0,
      draw_id: "",
      date_added: "",
      status: "",
      invoice: "",
      attempts: 0,
      timeout: false,
    }

      this.getQueueData = this.getQueueData.bind(this);
  }

  componentDidMount () {
    window.scrollTo(0, 0);
    this.getQueueData();
    window.scrollTo(0, 0);
  }

  async getQueueData() {
    console.log("hit queue")
    let myOrderQueue = await getQueueOrderDetails(this.state.id);
        //let increase = this.state.attempts + 1;
        //this.setState({attempts: increase})
        if(myOrderQueue.length > 0) {
            let myAdded = ""
            if(myOrderQueue[0].date_added !== null) {
              myAdded = myOrderQueue[0].date_added.replace(" ", "T");
              let newDate = new Date(myAdded)
              myAdded = newDate.getFullYear() + "-" + this.twoDigits(1 + newDate.getMonth()) + "-" + this.twoDigits(newDate.getDate()) + " " + this.twoDigits(newDate.getHours()) + ":" + this.twoDigits(newDate.getMinutes()) + ":00";
              myAdded = myAdded.replace(" ", "T");
              this.setState({date_added: myAdded});
            }

            this.setState({stripe_customer_id: myOrderQueue[0].stripe_customer_id});
            this.setState({customer_id: myOrderQueue[0].customer_id});
            this.setState({card_id: myOrderQueue[0].card_id});
            this.setState({tickets: myOrderQueue[0].tickets});
            this.setState({draw_id: myOrderQueue[0].draw_id});
            this.setState({status: myOrderQueue[0].status});
            this.setState({invoice: myOrderQueue[0].invoice});
            this.setState({stripe_price_id: myOrderQueue[0].stripe_price_id});
            this.setState({stripe_product_id: myOrderQueue[0].stripe_product_id});
        }

    if(this.state.status === "New" || this.state.status === "InProgress" || this.state.status === "ChargeAttempt") {
      console.log("Hit queue data")
      if(this.state.attempts < 400) {
        setTimeout(this.getQueueData(), 10000);
      }
      else {
        this.setState({timeout: true});
      }
    }
  }

  twoDigits(date) {
    if(0 <= date && date < 10) return "0" + date.toString();
    if(-10 < date && date < 0) return "-0" + (-1*date).toString();
    return date.toString();
  }

  render() {
    return (
        <div className="tabContent">
          <MediaQuery query='(min-width: 800px)'>
            <div style={{margin: "20px", marginTop: "40px", marginBottom: "100px", display: "inline"}}>
              {this.state.status === "New" &&
                  <div>
                  <h3>You Are Waiting In Line</h3>
                  <p>Your order is in a queue waiting to be processed.</p>
                  <br/><br/>
                  <div className="loader"/>
              </div>
              }

              {this.state.status === "InProgress" &&
                  <div>
                      <h3>You Are Waiting In Line</h3>
                      <p>Your order is in a queue being processed.</p>
                          <br/><br/>
                      <div className="loader"/>
                      
                  </div>
              }

            {this.state.status === "ChargeAttempt" && this.state.timeout === false &&
                  <div>
                      <h3>You Are Waiting In Line</h3>
                      <p>We are attempting to charge your card now.</p>
                          <br/><br/>
                      <div className="loader"/>
                      
                  </div>
              }

              {this.state.status === "ChargeAttempt" && this.state.timeout === true &&
                  <div>
                      <h3>Something Went Wrong</h3>
                      <p>We were unable to process your payment for an unknown reason.</p>
                      <br/><br/>
                      <Link 
                          className="raffle-button" 
                          to={"/purchase/" + this.state.draw_id}>
                          Try Again
                      </Link>
                  </div>
              }

              {this.state.status === "PaymentFailed" &&
                  <div>
                      <h3>Your Order Failed.</h3>
                      <p>Your order could not be completed because the payment failed.
                      Click the button below to go back to the payments page to try again.</p>
                      <br/><br/>
                      <Link 
                          className="raffle-button" 
                          to={"/purchase/" + this.state.draw_id}>
                          Try Again
                      </Link>
                  </div>
              }

              {this.state.status === "SoldOut" &&
                  <div>
                      <h3>Your Order Failed.</h3>
                      <p>Your order could not be completed because all the tickets for this draw have sold out.</p>
                      <br/><br/>
                      <Link 
                          className="raffle-button" 
                          to="/">
                          Go Home
                      </Link>
                  </div>
              }

              {this.state.status === "TooManyTickets" &&
                  <div>
                      <h3>Your Order Failed.</h3>
                      <p>Your order could not be completed because the amount of tickets requested is not available. 
                      Click the button below to go back to the payments page to try again.</p>
                      <br/><br/>
                      <Link 
                          className="raffle-button" 
                          to={"/purchase/" + this.state.draw_id}>
                          Try Again
                      </Link>
                  </div>
              }

              {this.state.status === "Success" &&
                  <div>
                      <h3>Your Order Was Successfully Placed!</h3>
                      <p>Your ticket(s) will be mailed to your mailing address via Canada Post.</p>
                      <p>Your order was placed successfully, click on the button below to view your invoice.</p>
                      <br/><br/>
                      <a 
                          className="raffle-button" 
                          href={this.state.invoice} target="_blank" rel="noreferrer">
                          View Invoice
                      </a>
                  </div>
              }
              <br/><br/>

              <h3>Order Request Info:</h3>
              <br/><br/>
              <div className="input-item">
                  <p >Order Submitted:</p>
                  <TextField
                  className="input-item-field"
                  variant="outlined"
                  id="date_added"
                  type="datetime-local"
                  disabled
                  value={this.state.date_added}
                  />
              </div><br/>
              <div className="input-item">
                  <p >Number of Tickets Requested:</p>
                  <TextField className="input-item-field" id="tickets" variant="outlined" type="number"  disabled value={this.state.tickets}/> 
              </div><br/>
              <br/>
              <br/>
          </div>
        </MediaQuery>
        <MediaQuery query='(max-width: 799px)'>
        <div style={{margin: "20px", marginTop: "40px", marginBottom: "100px", display: "inline"}}>
              {this.state.status === "New" &&
                  <div>
                  <h3>You Are Waiting In Line</h3>
                  <p>Your order is in a queue waiting to be processed.</p>
                  <br/><br/>
                  <div className="loader"/>
              </div>
              }

              {this.state.status === "InProgress" &&
                  <div>
                      <h3>You Are Waiting In Line</h3>
                      <p>Your order is in a queue being processed.</p>
                          <br/><br/>
                      <div className="loader"/>
                      
                  </div>
              }

            {this.state.status === "ChargeAttempt" && this.state.timeout === false &&
                  <div>
                      <h3>You Are Waiting In Line</h3>
                      <p>We are attempting to charge your card now.</p>
                          <br/><br/>
                      <div className="loader"/>
                      
                  </div>
              }

              {this.state.status === "ChargeAttempt" && this.state.timeout === true &&
                  <div>
                      <h3>Something Went Wrong</h3>
                      <p>We were unable to process your payment for an unknown reason.</p>
                      <br/><br/>
                      <Link 
                          className="raffle-button" 
                          to={"/purchase/" + this.state.draw_id}>
                          Try Again
                      </Link>
                  </div>
              }

              {this.state.status === "PaymentFailed" &&
                  <div>
                      <h3>Your Order Failed.</h3>
                      <p>Your order could not be completed because the payment failed.
                      Click the button below to go back to the payments page to try again.</p>
                      <br/><br/>
                      <Link 
                          className="raffle-button" 
                          to={"/purchase/" + this.state.draw_id}>
                          Try Again
                      </Link>
                  </div>
              }

              {this.state.status === "SoldOut" &&
                  <div>
                      <h3>Your Order Failed.</h3>
                      <p>Your order could not be completed because all the tickets for this draw have sold out.</p>
                      <br/><br/>
                      <Link 
                          className="raffle-button" 
                          to="/">
                          Go Home
                      </Link>
                  </div>
              }

              {this.state.status === "TooManyTickets" &&
                  <div>
                      <h3>Your Order Failed.</h3>
                      <p>Your order could not be completed because the amount of tickets requested is not available. 
                      Click the button below to go back to the payments page to try again.</p>
                      <br/><br/>
                      <Link 
                          className="raffle-button" 
                          to={"/purchase/" + this.state.draw_id}>
                          Try Again
                      </Link>
                  </div>
              }

              {this.state.status === "Success" &&
                  <div>
                      <h3>Your Order Was Successfully Placed!</h3>
                      <p>Your ticket(s) will be mailed to your mailing address via Canada Post.</p>
                      <p>Your order was placed successfully, click on the button below to view your invoice.</p>
                      <br/><br/>
                      <a 
                          className="raffle-button" 
                          href={this.state.invoice} target="_blank" rel="noreferrer">
                          View Invoice
                      </a>
                  </div>
              }
              <br/>

              <h3>Order Request Info:</h3>
              <div>
                  <p >Order Submitted:</p><br/>
                  <TextField
                  className="input-item-field"
                  variant="outlined"
                  id="date_added"
                  type="datetime-local"
                  disabled
                  value={this.state.date_added}
                  />
              </div><br/>
              <div>
                  <p >Number of Tickets Requested:</p><br/>
                  <TextField className="input-item-field" id="tickets" variant="outlined" type="number"  disabled value={this.state.tickets}/> 
              </div><br/>
              <br/>
              <br/>
          </div>
        </MediaQuery>
      </div>
    );
  }
}

async function getQueueOrderDetails(queueID) {
    let myJson = {};
    myJson.id = queueID;
    let data = {};
  
    await (async () => {
      const rawResponse = await fetch('/api/v1/queue/get_details', {
        method: 'post',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(myJson)
      });
      data = await rawResponse.json();
    })();
  
    //console.log(data);
  
    return data;
}

export default OrderQueuePage;