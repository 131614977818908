import React from 'react';
import StripeCheckout from "react-stripe-checkout";
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import Modal from 'react-modal';
import history from './history';
import axios from 'axios';
import MediaQuery from 'react-responsive';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    zIndex: "5000",
    backgroundColor: "white"
  }
};

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const key = 'pk_live_51KR1ZwDzG1n38rCectn2RLGjH9wAc04RV5gAOTEOyNiV99PEPiEYI2fIh6McXWXfGhwFVTPmhef9hdesoJ6xDl5D00xMJzXyYV';

class PurchaseForm extends React.Component {
  constructor(props) {
    super(props);

    let pathname = props.location.pathname;
    let id = pathname.split("/")[2];

    this.state = {
      id: id,
      name: "",
      description: "",
      start: "",
      end: "",
      tickets: 0,
      ticket_price: 0,
      full_ticket_price: 0,
      over_nineteen: false,
      aboveAge: false,
      fullPrice: false,
      customerName: "",
      customerLastname: "",
      customerEmail: "",
      addressLineOne: "",
      addressLineTwo: "",
      postalCode: "",
      city: "",
      customerPhone: "",
      ticketAmount: 1,
      poBox: "",
      stripe_full_price: "",
      stripe_price: "",
      stripe_product: "",
      errorLabel: "",
      valid: false,
      loading: true,
      addressCorrect: false,
      dateValid: false,
      modalIsOpen: false,
    }

    this.closeModal = this.closeModal.bind(this);
    this.handleStateChange = this.handleStateChange.bind(this);
    this.handleCheckboxChangePrice = this.handleCheckboxChangePrice.bind(this);
    this.getRaffleData = this.getRaffleData.bind(this);
    this.cancelPurchase = this.cancelPurchase.bind(this);
    this.createPurchase = this.createPurchase.bind(this);
    this.validateInputs = this.validateInputs.bind(this);
    this.calculateTotal = this.calculateTotal.bind(this);
    this.makePayment = this.makePayment.bind(this);
    this.twoDigits = this.twoDigits.bind(this)
    this.handleCheckboxChangeAddress = this.handleCheckboxChangeAddress.bind(this)
    this.dateFormated = this.dateFormated.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.getRaffleData();
  }

  async closeModal() {
    await history.push("/lottery/" + this.state.id);
    window.scrollTo(0, 0);
    window.location.reload();
    this.setState({ modalIsOpen: false });
  }

  async getRaffleData() {
    try {
      let res = await getIPDetails();
      if (res.region !== "Ontario" && res.country_code !== "CA") {
        this.setState({ modalIsOpen: true });
        this.setState({ errorLabel: "You must be located in Ontario to purchase a ticket." })
        this.setState({ valid: false })
      }
    }
    catch (err) {
      console.log(err);
      this.setState({ modalIsOpen: true });
      this.setState({ errorLabel: "You must be located in Ontario to purchase a ticket." })
      this.setState({ valid: false })
    }

    let dateData = await getDrawDateDetails(this.state.id);

    if (dateData.length > 0) {
      this.setState({ dateValid: true })
      let myRaffles = await getPurchaseForm(this.state.id);
      if (myRaffles.length > 0) {
        this.setState({ name: myRaffles[0].name });
        this.setState({ description: myRaffles[0].description });
        this.setState({ start: myRaffles[0].start });
        this.setState({ end: myRaffles[0].end });
        this.setState({ tickets: myRaffles[0].tickets });
        this.setState({ ticket_price: myRaffles[0].ticket_price });
        this.setState({ full_ticket_price: myRaffles[0].full_ticket_price });
        this.setState({ over_nineteen: myRaffles[0].over_nineteen });
        this.setState({ stripe_full_price: myRaffles[0].stripe_full_price });
        this.setState({ stripe_price: myRaffles[0].stripe_price });
        this.setState({ stripe_product: myRaffles[0].stripe_product });
        this.setState({ amount: myRaffles[0].amount });

        if (this.state.amount <= 0) {
          this.setState({ loading: false })
          await history.push("/lottery/" + this.state.id);
          window.scrollTo(0, 0);
          window.location.reload();
        }
      }
    }

    this.setState({ loading: false })
  }

  handleStateChange = async (e) => {
    await this.setState({ [e.target.id]: e.target.value });
    this.validateInputs();
  }

  handleCheckboxChange = async (e) => {
    let newValue = !this.state.aboveAge;
    await this.setState({ aboveAge: newValue });
    this.validateInputs();
  }

  handleCheckboxChangeAddress = async (e) => {
    let newValue = !this.state.addressCorrect;
    await this.setState({ addressCorrect: newValue });
    this.validateInputs();
  }

  handleCheckboxChangePrice = async (e) => {
    let newValue = !this.state.fullPrice;
    await this.setState({ fullPrice: newValue });
    this.validateInputs();
  }

  twoDigits(date) {
    if (0 <= date && date < 10) return "0" + date.toString();
    if (-10 < date && date < 0) return "-0" + (-1 * date).toString();
    return date.toString();
  }

  dateFormated(dateString) {
    let myDate = new Date(dateString);
    //console.log(myDate)

    let newDate = myDate.getFullYear() + "-" + this.twoDigits(1 + myDate.getMonth()) + "-" + this.twoDigits(myDate.getDate()) + " " + this.twoDigits(myDate.getHours()) + ":" + this.twoDigits(myDate.getMinutes()) + ":00";
    //console.log(newDate)
    return newDate;
  }

  async cancelPurchase() {
    await history.push("/lottery/" + this.state.id);
    window.scrollTo(0, 0);
    window.location.reload();
  }

  async createPurchase(token) {
    this.setState({ errorLabel: "" })

    console.log("Checking for validation");

    let validation = await this.validateInputs();

    if (validation === true) {
      console.log("Valid customer data");

      this.setState({ loading: true })

      let myJson = {};

      let myDate = new Date()
      myJson.my_date = this.dateFormated(myDate)

      myJson.firstname = this.state.customerName;
      myJson.lastname = this.state.customerLastname;
      myJson.email = this.state.customerEmail;
      myJson.phone = this.state.customerPhone;

      myJson.address_line_one = this.state.addressLineOne;
      myJson.address_line_two = this.state.addressLineTwo;
      myJson.postal_code = this.state.postalCode;
      myJson.po_box = this.state.poBox;
      myJson.city = this.state.city;
      myJson.province = "Ontario";
      myJson.country = "Canada";
      let address = "";

      if (this.state.poBox !== "") {
        address = "PO.Box: " + this.state.poBox + ", ";
      }

      if (this.state.addressLineTwo !== "") {
        address = address + this.state.addressLineOne + ", " + this.state.addressLineTwo + ", " + this.state.postalCode + ", Ontario, Canada";
      }
      else {
        address = address + this.state.addressLineOne + ", " + this.state.postalCode + ", Ontario, Canada";
      }

      myJson.address = address;

      try {
        let response = await addStripeCustomer(myJson);
        console.log(response);

        if (response.id !== undefined && response.err === undefined) {
          console.log("Added stripe customer");
          let createCardJson = {};
          createCardJson.customer_id = response.id;
          createCardJson.card_token = token;
          let cardResponse = await addStripeCard(createCardJson);
          //console.log(cardResponse.id)
          //console.log(cardResponse.id)
          myJson.card_id = cardResponse.id;

          myJson.stripe_id = response.id;
          myJson.stripe_customer_id = response.id;
          myJson.stripe_product_id = this.state.stripe_product;
          myJson.tickets = this.state.ticketAmount;
          myJson.draw_id = this.state.id;

          console.log("Added stripe card");

          if (this.state.fullPrice === true) {
            //console.log("Charge full price");
            myJson.stripe_price_id = this.state.stripe_full_price;
            let orderQueueResult = await addOrderQueue(myJson);

            if (orderQueueResult.error === undefined) {
              console.log("Added order queue");
              await history.push("/queue/" + orderQueueResult.insertId);
              window.scrollTo(0, 0);
              window.location.reload();
            }
            else {
              //console.log(orderQueueResult.error);
              this.setState({ errorLabel: "There was an error adding your order to the queue" })
            }
          }
          else {
            //console.log("Charge set price");
            myJson.stripe_price_id = this.state.stripe_price;
            let orderQueueResult = await addOrderQueue(myJson);

            if (orderQueueResult.error === undefined) {
              console.log("Added order queue");
              await history.push("/queue/" + orderQueueResult.insertId);
              window.scrollTo(0, 0);
              window.location.reload();
            }
            else {
              //console.log(orderQueueResult.error);
              this.setState({ errorLabel: "There was an error adding your order to the queue" })
            }
          }
        }
        else {
          console.log("Invalid customer data");
          this.setState({ errorLabel: "We were unable to add you as a customer. Please check your inputs and try again." })
          this.setState({ valid: false })
        }

        this.setState({ loading: false })
      }
      catch (err) {
        console.log("Error");
        console.log(err)
        this.setState({ errorLabel: "An unexpected error occured: " + err })
        this.setState({ valid: false })
        this.setState({ loading: false })
      }
    }
    else {
      console.log("Invalid inputs");
      this.setState({ errorLabel: "We were unable to add you as a customer. Please check your inputs and try again." })
      this.setState({ valid: false })
    }
  }

  async validateInputs() {
    let firstname = this.state.customerName.replaceAll(/\s/g, '')
    let lastname = this.state.customerLastname.replaceAll(/\s/g, '')
    let email = this.state.customerEmail.replaceAll(/\s/g, '')
    let phone = this.state.customerPhone.replaceAll(/\s/g, '')
    let address = this.state.addressLineOne.replaceAll(/\s/g, '')
    let city = this.state.city.replaceAll(/\s/g, '')

    if (this.state.aboveAge === false) {
      this.setState({ errorLabel: "You must confirm to being above the age requirement" })
      this.setState({ valid: false })
      return false;
    }
    else if (this.state.addressCorrect === false) {
      this.setState({ errorLabel: "You must confirm your address is correct" })
      this.setState({ valid: false })
      return false;
    }
    else if (parseFloat(this.state.amount) < parseFloat(this.state.ticketAmount)) {
      if (this.state.amount === 1) {
        this.setState({ errorLabel: "There is only " + this.state.amount + " ticket left, you can not purchase " + this.state.ticketAmount })
      }
      else if (this.state.amount === 0) {
        this.setState({ errorLabel: "Sorry, this lottery is now sold out" })
      }
      else {
        this.setState({ errorLabel: "There are only " + this.state.amount + " tickets left, you can not purchase " + this.state.ticketAmount })
      }

      this.setState({ valid: false })
      return false;
    }
    else if (firstname.length === 0) {
      this.setState({ errorLabel: "You must enter a first name" })
      this.setState({ valid: false })
      return false;
    }
    else if (lastname.length === 0) {
      this.setState({ errorLabel: "You must enter a last name" })
      this.setState({ valid: false })
      return false;
    }
    else if (email.length <= 8) {
      this.setState({ errorLabel: "You must enter a valid email address" })
      this.setState({ valid: false })
      return false;
    }
    else if (!(this.state.customerEmail.includes("@"))) {
      this.setState({ errorLabel: "You must enter a valid email address" })
      this.setState({ valid: false })
      return false;
    }
    else if (!(this.state.customerEmail.includes("."))) {
      this.setState({ errorLabel: "You must enter a valid email address" })
      this.setState({ valid: false })
      return false;
    }
    else if (phone.length <= 9) {
      this.setState({ errorLabel: "You must enter a phone number" })
      this.setState({ valid: false })
      return false;
    }
    else if (address.length <= 4) {
      this.setState({ errorLabel: "You must enter a valid address" })
      this.setState({ valid: false })
      return false;
    }
    else if (this.state.postalCode.length !== 7) {
      this.setState({ errorLabel: "Postal code must be in the format A1A 1A1" })
      this.setState({ valid: false })
      return false;
    }
    else if (!(this.state.postalCode[1] >= '0' && this.state.postalCode[1] <= '9') ||
      !(this.state.postalCode[4] >= '0' && this.state.postalCode[4] <= '9') ||
      !(this.state.postalCode[6] >= '0' && this.state.postalCode[6] <= '9')) {
      this.setState({ errorLabel: "Postal code must be in the format A1A 1A1" })
      this.setState({ valid: false })
      return false;
    }
    else if (!(this.state.postalCode[0].match(/[a-z]/i)) ||
      !(this.state.postalCode[2].match(/[a-z]/i)) ||
      !(this.state.postalCode[5].match(/[a-z]/i))) {
      this.setState({ errorLabel: "Postal code must be in the format A1A 1A1" })
      this.setState({ valid: false })
      return false;
    }
    else if (this.state.postalCode[3] !== ' ') {
      this.setState({ errorLabel: "Postal code must be in the format A1A 1A1" })
      this.setState({ valid: false })
      return false;
    }
    else if (city.length <= 2) {
      this.setState({ errorLabel: "You must enter a valid city" })
      this.setState({ valid: false })
      return false;
    }
    else if (this.state.ticketAmount <= 0) {
      this.setState({ errorLabel: "You must purchase at least one ticket" })
      this.setState({ valid: false })
      return false;
    }
    else if (this.state.ticketAmount > 10) {
      this.setState({ errorLabel: "You can only purchase a maximum of 10 tickets" })
      this.setState({ valid: false })
      return false;
    }

    try {
      let res = await getIPDetails();
      if (res.region !== "Ontario" && res.country_code !== "CA") {
        this.setState({ modalIsOpen: true });
        this.setState({ errorLabel: "You must be located in Ontario to purchase a ticket." })
        this.setState({ valid: false })
        return false;
      }
    }
    catch (err) {
      console.log(err);
      this.setState({ modalIsOpen: true });
      this.setState({ errorLabel: "You must be located in Ontario to purchase a ticket." })
      this.setState({ valid: false })
      return false;
    }

    console.log("Valid")

    this.setState({ errorLabel: "" })
    this.setState({ valid: true })

    return true;
  }

  calculateTotal() {
    if (this.state.fullPrice === true) {
      return (this.state.full_ticket_price * this.state.ticketAmount).toFixed(2)
    }
    else {
      return (this.state.ticket_price * this.state.ticketAmount).toFixed(2)
    }
  }

  makePayment = token => {
    console.log(token)
    this.createPurchase(token.id)
  }

  render() {
    return (
      <div className="tabContent">
        <Modal
          isOpen={this.state.modalIsOpen}
          onRequestClose={this.closeModal}
          style={customStyles}
          contentLabel="Example Modal"
          ariaHideApp={false}
        >
          <div style={{ textAlign: "center", zIndex: "5000" }}>
            <h1 style={{ fontSize: "40px" }}>Error</h1>
            <p >Due to Ontario lottery rules we need to confirm you are purchasing the ticket within Ontario. You are either not in Ontario, or your device privacy settings are blocking our location check. Adjust your device settings if you are on a cell phone or tablet, try purchasing from a computer or laptop, or contact a St. Marys Rotarian club member to purchase a ticket. Thank You for supporting the St. Marys Rotary club!</p>
            <button onClick={this.closeModal}>Okay</button>
          </div>
        </Modal>
        <h3>Purchase Tickets for {this.state.name}</h3>
        <br />
        <br />
        {this.state.loading === true &&
          <div>
            <br />
            <br />
            <div className="loader"></div>
            <br />
            <br />
          </div>
        }

        {this.state.loading === false && this.state.dateValid === false &&
          <p>You can not purchase tickets for this lottery at this time.</p>
        }

        {this.state.loading === false && this.state.dateValid === true && this.state.modalIsOpen === true &&
          <div>
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
          </div>
        }

        {this.state.loading === false && this.state.dateValid === true && this.state.modalIsOpen === false &&
          <div>
            <MediaQuery query='(min-width: 800px)'>
              <div className="input-item">
                <p>Firstname:</p>
                <TextField className="input-item-field" id="customerName" variant="outlined" required onChange={this.handleStateChange} value={this.state.customerName} />
              </div><br />

              <div className="input-item">
                <p>Lastname:</p>
                <TextField className="input-item-field" id="customerLastname" variant="outlined" required onChange={this.handleStateChange} value={this.state.customerLastname} />
              </div><br />

              <div className="input-item">
                <p>Email:</p>
                <TextField className="input-item-field" id="customerEmail" variant="outlined" required onChange={this.handleStateChange} value={this.state.customerEmail} />
              </div><br />

              <div className="input-item">
                <p>Phone:</p>
                <TextField className="input-item-field" id="customerPhone" variant="outlined" required onChange={this.handleStateChange} value={this.state.customerPhone} />
              </div><br /><br /><br /><br />
              <br />

              <p style={{ fontWidth: "bold", fontSize: "24px" }}>Shipping Address</p><br /><br />

              <div className="input-item">
                <p>PO Box:</p>
                <TextField className="input-item-field" id="poBox" variant="outlined" required onChange={this.handleStateChange} value={this.state.poBox} />
              </div><br />

              <div className="input-item">
                <p>Address Line 1:</p>
                <TextField className="input-item-field" id="addressLineOne" variant="outlined" required onChange={this.handleStateChange} value={this.state.addressLineOne} />
              </div><br />

              <div className="input-item">
                <p>Address Line 2:</p>
                <TextField className="input-item-field" id="addressLineTwo" variant="outlined" onChange={this.handleStateChange} value={this.state.addressLineTwo} />
              </div><br />

              <div className="input-item">
                <p>Postal Code:</p>
                <TextField className="input-item-field" id="postalCode" variant="outlined" onChange={this.handleStateChange} value={this.state.postalCode} />
              </div><p style={{ marginTop: "-15px", marginLeft: "100px" }}>Postal code must be in the format A1A 1A1</p><br />

              <div className="input-item">
                <p>City:</p>
                <TextField className="input-item-field" id="city" variant="outlined" onChange={this.handleStateChange} value={this.state.city} />
              </div><br />

              <div className="input-item">
                <p>Province:</p>
                <TextField className="input-item-field" disabled variant="outlined" value="Ontario" />
              </div><br />

              <div className="input-item">
                <p>Country:</p>
                <TextField className="input-item-field" disabled variant="outlined" value="Canada" />
              </div><br /><br />

              <p style={{ fontWidth: "bold", fontSize: "24px" }}>Order Summary:</p><br /><br />

              <div className="input-item">
                <p>Number of Tickets:</p>
                <TextField onWheel={(e) => e.target.blur()} className="input-item-field" id="ticketAmount" type="number" variant="outlined" required onChange={this.handleStateChange} value={this.state.ticketAmount} />
              </div><p style={{ marginTop: "-15px", marginLeft: "100px" }}>You can only purchase a maximum of 10 tickets</p><br /><br /><br /><br />

              {this.state.full_ticket_price > 0 &&
                <div>
                  <div className="input-item">
                    <p style={{ marginTop: "-30px" }}>If you would like to make an incremental donation to the Rotary Club of St. Marys (${(this.state.full_ticket_price - this.state.ticket_price).toFixed(2)} per ticket) click the box below. To make a more substantial donation click <a href="https://rotarystmarys.ca/donate/" target='_blank' rel="noreferrer">here</a>.</p>
                    <Checkbox
                      className="input-item-field-checkbox"
                      checked={this.state.fullPrice}
                      onChange={this.handleCheckboxChangePrice}
                      id="fullPrice"
                      color="primary"
                    />
                  </div>
                  <br /><br /><br /><br /><br /><br /><br /><br /><br />
                </div>
              }

              {this.state.fullPrice === true &&
                <div className="input-item">
                  <p>Price:</p>
                  <p>${(this.state.full_ticket_price).toFixed(2)}</p>
                </div>
              }
              {this.state.fullPrice === false &&
                <div className="input-item">
                  <p>Price:</p>
                  <p>${(this.state.ticket_price).toFixed(2)}</p>
                </div>
              }

              <br />

              {this.state.ticketAmount > 0 &&
                <div className="input-item">
                  <p>Total:</p>
                  <p>${this.calculateTotal()}</p>
                </div>
              }

              {this.state.over_nineteen === 1 &&
                <div>
                  <div className="input-item">
                    <p >I confirm that I am 19+ years of age and that if I win the draw, my prize will be revoked if I can not prove this.</p>
                    <Checkbox
                      className="input-item-field-checkbox"
                      checked={this.state.aboveAge}
                      onChange={this.handleCheckboxChange}
                      id="aboveAge"
                      color="primary"
                    />
                  </div>
                </div>
              }

              {this.state.over_nineteen === 0 &&
                <div>
                  <div className="input-item">
                    <p >I confirm that I am 18+ years of age and that if I win the draw, my prize will be revoked if I can not prove this.</p>
                    <Checkbox
                      className="input-item-field-checkbox"
                      checked={this.state.aboveAge}
                      onChange={this.handleCheckboxChange}
                      style={{ marginTop: "40px" }}
                      id="aboveAge"
                      color="primary"
                    />
                  </div>
                </div>
              }
              <br /> <br />
              <br /> <br />
              <br /> <br />

              <div className="input-item">
                <p>Due to Municipal Lottery Guidelines, we will be sending you your ticket through Canada Post. Therefore please make sure the address entered is accurate to ensure the safe arrival of your ticket. If this ticket is for an event it will be held for you at the door.</p>
                <Checkbox
                  className="input-item-field-checkbox"
                  checked={this.state.addressCorrect}
                  onChange={this.handleCheckboxChangeAddress}
                  style={{ marginTop: "60px" }}
                  id="addressCorrect"
                  color="primary"
                />
              </div>

              <br /> <br />
              <br /> <br />
              <br /> <br />
              <br /> <br />
              <br /> <br />
              <br /> <br />
              <p>If you are not emailed an invoice after entering your card information your purchase did not go through. If this happens and you are on a cell phone, try purchasing on a laptop or contact a rotary club member to purchase a ticket.</p>
              <br /> <br />

              <button className="cancel-button" onClick={this.cancelPurchase}>Cancel</button>
              {this.state.valid === false &&
                <button className="raffle-button-disabled">Place Order</button>
              }

              {this.state.valid === true &&
                <StripeCheckout
                  stripeKey={key}
                  token={this.makePayment}
                  name="Purchase Tickets"
                  amount={this.calculateTotal() * 100}
                  currency="CAD"
                >
                  <button className="raffle-button">Place Order</button>
                </StripeCheckout>
              }

              <br /><br /><br />
              <p className="errorLabel" >{this.state.errorLabel}</p>
              <br />
            </MediaQuery>
            <MediaQuery query='(max-width: 799px)'>
              <div>
                <p>Firstname:</p><br />
                <TextField className="input-item-field" id="customerName" variant="outlined" required onChange={this.handleStateChange} value={this.state.customerName} />
              </div><br />

              <div>
                <p>Lastname:</p><br />
                <TextField className="input-item-field" id="customerLastname" variant="outlined" required onChange={this.handleStateChange} value={this.state.customerLastname} />
              </div><br />

              <div>
                <p>Email:</p><br />
                <TextField className="input-item-field" id="customerEmail" variant="outlined" required onChange={this.handleStateChange} value={this.state.customerEmail} />
              </div><br />

              <div>
                <p>Phone:</p><br />
                <TextField className="input-item-field" id="customerPhone" variant="outlined" required onChange={this.handleStateChange} value={this.state.customerPhone} />
              </div>
              <br />

              <p style={{ fontWidth: "bold", fontSize: "24px" }}>Shipping Address</p>

              <div>
                <p>PO Box:</p><br />
                <TextField className="input-item-field" id="poBox" variant="outlined" required onChange={this.handleStateChange} value={this.state.poBox} />
              </div><br />

              <div>
                <p>Address Line 1:</p><br />
                <TextField className="input-item-field" id="addressLineOne" variant="outlined" required onChange={this.handleStateChange} value={this.state.addressLineOne} />
              </div><br />

              <div>
                <p>Address Line 2:</p><br />
                <TextField className="input-item-field" id="addressLineTwo" variant="outlined" onChange={this.handleStateChange} value={this.state.addressLineTwo} />
              </div><br />

              <div>
                <p>Postal Code:</p><br />
                <TextField className="input-item-field" id="postalCode" variant="outlined" onChange={this.handleStateChange} value={this.state.postalCode} />
              </div><br />
              <p>Postal code must be in the format A1A 1A1</p><br />

              <div>
                <p>City:</p><br />
                <TextField className="input-item-field" id="city" variant="outlined" onChange={this.handleStateChange} value={this.state.city} />
              </div><br />

              <div>
                <p>Province:</p><br />
                <TextField className="input-item-field" disabled variant="outlined" value="Ontario" />
              </div><br />

              <div>
                <p>Country:</p><br />
                <TextField className="input-item-field" disabled variant="outlined" value="Canada" />
              </div><br />

              <p style={{ fontWidth: "bold", fontSize: "24px" }}>Order Summary:</p>

              <div>
                <p>Number of Tickets:</p><br />
                <TextField onWheel={(e) => e.target.blur()} className="input-item-field" id="ticketAmount" type="number" variant="outlined" required onChange={this.handleStateChange} value={this.state.ticketAmount} />
              </div><br />
              <p>You can only purchase a maximum of 10 tickets</p><br />

              {(this.state.full_ticket_price - this.state.ticket_price) > 0 &&
                <div>
                  <Checkbox
                    className="input-item-field-checkbox"
                    checked={this.state.fullPrice}
                    onChange={this.handleCheckboxChangePrice}
                    id="fullPrice"
                    color="primary"
                  />
                  <p>If you would like to make an incremental donation to the Rotary Club of St. Marys (${(this.state.full_ticket_price - this.state.ticket_price).toFixed(2)} per ticket) click the box below. To make a more substantial donation click <a href="https://rotarystmarys.ca/donate/" target='_blank' rel="noreferrer">here</a>.</p>
                </div>
              }

              <br />

              {this.state.fullPrice === true &&
                <p>Price: ${(this.state.full_ticket_price).toFixed(2)}</p>
              }
              {this.state.fullPrice === false &&
                <p>Price: ${(this.state.ticket_price).toFixed(2)}</p>
              }

              {this.state.ticketAmount > 0 &&
                <p>Total: ${this.calculateTotal()}</p>
              }

              {this.state.over_nineteen === 1 &&
                <div>
                  <Checkbox
                    className="input-item-field-checkbox"
                    checked={this.state.aboveAge}
                    onChange={this.handleCheckboxChange}
                    id="aboveAge"
                    color="primary"
                  />
                  <p >I confirm that I am 19+ years of age and that if I win the draw, my prize will be revoked if I can not prove this.</p>
                </div>
              }

              {this.state.over_nineteen === 0 &&
                <div>
                  <Checkbox
                    className="input-item-field-checkbox"
                    checked={this.state.aboveAge}
                    onChange={this.handleCheckboxChange}
                    style={{ marginTop: "40px" }}
                    id="aboveAge"
                    color="primary"
                  />
                  <p >I confirm that I am 18+ years of age and that if I win the draw, my prize will be revoked if I can not prove this.</p>
                </div>
              }

              <Checkbox
                className="input-item-field-checkbox"
                checked={this.state.addressCorrect}
                onChange={this.handleCheckboxChangeAddress}
                style={{ marginTop: "60px" }}
                id="addressCorrect"
                color="primary"
              />
              <p>Due to Municipal Lottery Guidelines, we will be sending you your ticket through Canada Post. Therefore please make sure the address entered is accurate to ensure the safe arrival of your ticket. If this ticket is for an event it will be held for you at the door.</p>

              <br />
              <p>If you are not emailed an invoice after entering your card information your purchase did not go through. If this happens and you are on a cell phone, try purchasing on a laptop or contact a rotary club member to purchase a ticket.</p>
              <br />

              <button className="cancel-button" onClick={this.cancelPurchase}>Cancel</button>
              {this.state.valid === false &&
                <button className="raffle-button-disabled">Place Order</button>
              }

              {this.state.valid === true &&
                <StripeCheckout
                  stripeKey={key}
                  token={this.makePayment}
                  name="Purchase Tickets"
                  amount={this.calculateTotal() * 100}
                  currency="CAD"
                >
                  <button className="raffle-button">Place Order</button>
                </StripeCheckout>
              }

              <br /><br /><br />
              <p className="errorLabel" >{this.state.errorLabel}</p>
              <br />
            </MediaQuery>
          </div>
        }
      </div>
    );
  }
}

async function getIPDetails() {
  const res = await axios.get('https://api.ipify.org?format=jso')
  console.log(res.data.ip);

  const ipData = await axios.get('https://ipapi.co/' + res.data.ip + '/json/');
  console.log(ipData.data)
  return ipData.data;
}

async function addStripeCustomer(myJson) {
  let data = {};

  await (async () => {
    const rawResponse = await fetch('/api/v1/stripe/create_customer', {
      method: 'post',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(myJson)
    });
    data = await rawResponse.json();
  })();

  //console.log(data);

  return data;
}

async function addStripeCard(myJson) {
  let data = {};

  await (async () => {
    const rawResponse = await fetch('/api/v1/stripe/create_card', {
      method: 'post',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(myJson)
    });
    data = await rawResponse.json();
  })();

  //console.log(data);

  return data;
}

async function addOrderQueue(myJson) {
  let data = {};

  await (async () => {
    const rawResponse = await fetch('/api/v1/queue/add', {
      method: 'post',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(myJson)
    });
    data = await rawResponse.json();
  })();

  //console.log(data);

  return data;
}

async function getDrawDateDetails(raffleID) {
  let myJson = {};
  myJson.id = raffleID;
  let data = {};

  await (async () => {
    const rawResponse = await fetch('/api/v1/draws/check_date', {
      method: 'post',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(myJson)
    });
    data = await rawResponse.json();
  })();

  //console.log(data);

  return data;
}


async function getPurchaseForm(raffleID) {
  let myJson = {};
  myJson.id = raffleID;
  let data = {};

  await (async () => {
    const rawResponse = await fetch('/api/v1/draws/get_details', {
      method: 'post',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(myJson)
    });
    data = await rawResponse.json();
  })();

  //console.log(data);

  return data;
}

export default PurchaseForm;