import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Modal from 'react-modal';
import history from './history';
import axios from 'axios';

const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)'
    }
  };

class HomeForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      raffles: [],
      loading: true,
      modalIsOpen: false,
    }

    this.closeModal = this.closeModal.bind(this);
    this.getRaffleData = this.getRaffleData.bind(this);
    this.parsedData = this.parsedData.bind(this);
    this.convertDate = this.convertDate.bind(this);
    this.checkIP = this.checkIP.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.getRaffleData();
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  async getRaffleData() {
    let myRaffles = await getRaffles();
    this.setState({ raffles: myRaffles, loading: false });
  }

  convertDate(oldDate) {
    let newDate = new Date(oldDate)
    if (newDate) {
      return newDate.toLocaleString('default', { dateStyle: 'full' });
    }
    else {
      return "";
    }
  }

  async checkIP(raffleID) {
    try {
      let res = await getIPDetails();
      if(res.region !== "Ontario" && res.country_code !== "CA") {
        this.setState({ modalIsOpen: true });
      }
      else {
        await history.push("/lottery/" + raffleID);
        window.scrollTo(0, 0);
        window.location.reload();
      }
    }
    catch(err) {
      console.log(err);
      this.setState({ modalIsOpen: true });
    }
  }

  parsedData() {
    return (
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
      >
        {this.state.raffles.map((raffle, index) => (
          <Card key={raffle.id} variant="outlined" style={{ width: "400px", height: "500px", margin: "10px", border: '3px solid #e1e1e1', borderRadius: "10px" }} >
            <CardContent>
              <h3 className="raffle_title">{raffle.name}</h3>
              <div style={{ borderBottom: "2px solid #005daa", width: "380px", marginLeft: "-6px" }} />
              <br />
              <div style={{ height: "250px" }}>
                <p style={{ fontSize: "14px" }}>{this.convertDate(raffle.start)}<br />to<br />{this.convertDate(raffle.end)}</p>
                <p className="raffle_card_description">{raffle.description}</p>
              </div>
              <button className="raffle-button" onClick={() => this.checkIP(raffle.id)}>Learn More</button>
            </CardContent>
          </Card>
        ))}
      </Grid>
    );
  }

  render() {
    return (
      <div>
        <Modal
          isOpen={this.state.modalIsOpen}
          onRequestClose={this.closeModal}
          style={customStyles}
          contentLabel="Example Modal"
          ariaHideApp={false}
        >
          <div style={{ textAlign: "center" }}>
            <h1 style={{ fontSize: "40px" }}>Error</h1>
            <p >Due to Ontario lottery rules we need to confirm you are purchasing the ticket within Ontario. You are either not in Ontario, or your device privacy settings are blocking our location check. Adjust your device settings if you are on a cell phone or tablet, try purchasing from a computer or laptop, or contact a St. Marys Rotarian club member to purchase a ticket. Thank You for supporting the St. Marys Rotary club!</p>
            <button onClick={this.closeModal}>Okay</button>
          </div>
        </Modal>
        <div className="tabContent">
          <div style={{ margin: "20px", marginTop: "10px", marginBottom: "10px" }}>
            <h3>Rotary Municipal Lotteries</h3>
            <br />
            <br />

            {this.state.loading === true &&
              <div>
                <br />
                <br />
                <div className="loader"></div>
                <br />
                <br />
              </div>
            }

            {this.state.raffles.length === 0 && this.state.loading === false &&
              <div>
                <p>There are currently no lotteries open for purchase.</p>
                <br />
                <br />
                <br />
                <br />
                <br />
              </div>
            }

            {this.state.raffles.length > 0 && this.state.loading === false &&
              <div>
                <p>Scroll down to see more lotteries.</p>
                {this.parsedData()}
              </div>
            }
          </div>
        </div>
      </div>
    );
  }
}

async function getIPDetails() {
  const res = await axios.get('https://api.ipify.org?format=json')
  console.log(res.data.ip);

  const ipData = await axios.get('https://ipapi.co/' + res.data.ip + '/json/');
  console.log(ipData.data)
  return ipData.data;
}

async function getRaffles() {
  let data = {};

  await (async () => {
    const rawResponse = await fetch('/api/v1/draws/get_active', {
      method: 'get'
    });
    data = await rawResponse.json();
  })();

  //console.log(data);

  return data;
}

export default HomeForm;