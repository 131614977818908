import React from 'react';
import TextField from '@material-ui/core/TextField';
import history from './history';
import Checkbox from '@material-ui/core/Checkbox';

class EditRaffleForm extends React.Component {
  constructor(props) {
    super(props);

    let pathname = props.location.pathname;
    let id = pathname.split("/")[2];

    this.state = {
      id: id,
      name: "",
      description: "", 
      start: "",
      end: "",
      tickets: 0,
      over_nineteen: false,
      value: 0,
      location: "",
      pre_post_draw: false,
      pre_post_draw_date: "",
      pre_post_draw_value: 0.0,
      pre_post_draw_description: "",
      pre_post_draw_location: "",
      loading: true,
      liscence: "",
      editable: false,
    }
  
      this.handleStateChange = this.handleStateChange.bind(this);
      this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
      this.updateRaffle = this.updateRaffle.bind(this);
      this.getRaffleData = this.getRaffleData.bind(this);
      this.validateInputs = this.validateInputs.bind(this);
      this.cancelEdit = this.cancelEdit.bind(this);
      this.dateFormated = this.dateFormated.bind(this)
  }

  componentDidMount () {
    window.scrollTo(0, 0);
    this.getRaffleData();
  }

  twoDigits(date) {
    if(0 <= date && date < 10) return "0" + date.toString();
    if(-10 < date && date < 0) return "-0" + (-1*date).toString();
    return date.toString();
  }

  dateFormated(dateString) {
    let myDate = new Date(dateString);
    
    let newDate = myDate.getFullYear() + "-" + this.twoDigits(1 + myDate.getMonth()) + "-" + this.twoDigits(myDate.getDate()) + " " + this.twoDigits(myDate.getHours()) + ":" + this.twoDigits(myDate.getMinutes()) + ":00";
    return newDate;
}

  async getRaffleData() {
    
    let dateData = await getDrawDateDetails(this.state.id);

    if(dateData.length === 0) {
      this.setState({editable: true})
    }

    let endDateData = await getDrawEndDateDetails(this.state.id);

    if(endDateData.length === 0) {
      this.setState({editable: false})
    }

    let authData = getCookieData();
    //console.log(authData);

    if(authData.username && authData.token) { 
      let authCheck = checkAuth(authData.username, authData.token);

      if(authCheck.length === 0) {
        await history.push("/login");
        window.scrollTo(0, 0);
        window.location.reload();
        this.props.changeRemoveAuth();
      }
      else {
        let myRaffles = await getRaffleDetails(this.state.id);
        if(myRaffles.length > 0) {
          let myStart = ""
          if(myRaffles[0].start !== null) {
            myStart = myRaffles[0].start.replace(" ", "T");
            let newDate = new Date(myStart)
            myStart = newDate.getFullYear() + "-" + this.twoDigits(1 + newDate.getMonth()) + "-" + this.twoDigits(newDate.getDate()) + " " + this.twoDigits(newDate.getHours()) + ":" + this.twoDigits(newDate.getMinutes()) + ":00";
            myStart = myStart.replace(" ", "T");
          }
          let myEnd = "";
          if(myRaffles[0].end !== null) {
            myEnd = myRaffles[0].end.replace(" ", "T");
            let newDate = new Date(myEnd)

            myEnd = newDate.getFullYear() + "-" + this.twoDigits(1 + newDate.getMonth()) + "-" + this.twoDigits(newDate.getDate()) + " " + this.twoDigits(newDate.getHours()) + ":" + this.twoDigits(newDate.getMinutes()) + ":00";
            myEnd = myEnd.replace(" ", "T");
          }

            this.setState({name: myRaffles[0].name});
            this.setState({description: myRaffles[0].description});
            this.setState({start: myStart});
            this.setState({end: myEnd});
            this.setState({tickets: myRaffles[0].tickets});
            this.setState({value: myRaffles[0].value});
            this.setState({over_nineteen: myRaffles[0].over_nineteen});
            this.setState({location: myRaffles[0].location});
            this.setState({liscence: myRaffles[0].liscence});

            this.setState({pre_post_draw: myRaffles[0].pre_post_draw});
            this.setState({pre_post_draw_date: myRaffles[0].pre_post_draw_date});
            this.setState({pre_post_draw_description: myRaffles[0].pre_post_draw_description});
            this.setState({pre_post_draw_location: myRaffles[0].pre_post_draw_location});
            this.setState({pre_post_draw_value: myRaffles[0].pre_post_draw_value});
        }

        this.props.changeAuth();
        this.setState({loading: false})
      }
    }
    else {
      await history.push("/login");
      window.scrollTo(0, 0);
      window.location.reload();
      this.props.changeRemoveAuth();
    }
  }

  async cancelEdit() {
    await history.push("/adminlottery/" + this.state.id);
    window.scrollTo(0, 0);
    window.location.reload();
  }

  handleCheckboxChange = async(e) => {
    let newValue = !this.state.over_nineteen;
    await this.setState({ [e.target.id]: newValue });
  }

  handleStateChange = async(e) => {
    await this.setState({ [e.target.id]: e.target.value });
  }

  async updateRaffle() {
    if(this.validateInputs) {
      let myJson = {};
      myJson.id = this.state.id;
      myJson.name = this.state.name;
      myJson.description = this.state.description;

      let myStartDate = new Date(this.state.start)
        myJson.start = this.dateFormated(myStartDate)
        //console.log(myJson.start)

        let myEndDate = new Date(this.state.end)
        myJson.end = this.dateFormated(myEndDate)
        //console.log(myJson.end)

        myJson.liscence = this.state.liscence;
        myJson.tickets = parseFloat(this.state.tickets);
        myJson.over_nineteen = this.state.over_nineteen;
        myJson.ticket_price = parseFloat(this.state.ticket_price);
        myJson.full_ticket_price = parseFloat(this.state.full_ticket_price);
        myJson.value = parseFloat(this.state.value);
        myJson.reserved_tickets = parseFloat(this.state.reserved_tickets);
        myJson.location = this.state.location;

        //console.log(myJson.reserved_tickets)

        myJson.pre_post_draw = this.state.pre_post_draw;
        if(this.state.pre_post_draw_date !== "") {
          let myPostDate = new Date(this.state.pre_post_draw_date)
          myJson.pre_post_draw_date = this.dateFormated(myPostDate)
          //console.log(myJson.pre_post_draw_date)
        } else {
          myJson.pre_post_draw_date = "0000-00-00 00:00.000";
        }

      let response = await updateRaffleData(myJson);
      console.log(response);

      if(response.affectedRows !== undefined && response.affectedRows !== 0) {
        this.setState({loading: false});
          await history.push("/adminlottery/" + this.state.id);
          window.scrollTo(0, 0);
          window.location.reload();
      }
      else {
        this.setState({loading: false});
          //console.log(response.error);
      }
    }
    else {
        //console.log("Invalid input");
    }
  }

  validateInputs() {
    if(this.state.name === "") {
        return false;
    }
    else if(this.state.description === "") {
        return false;
    }
    else if(this.state.start === "") {
        return false;
    }
    else if(this.state.end === "") {
        return false;
    }
    else if(this.state.tickets <= 0) {
        return false;
    }
    else if(this.state.value <= 0) {
        return false;
    }

    if(this.state.pre_post_draw === true) {
      if(this.state.pre_post_draw_date === "") {
        return false;
      }
      else if(this.state.pre_post_draw_description === "") {
          return false;
      }
      else if(this.state.pre_post_draw_value <= 0) {
          return false;
      }
    }

    return true;
  }

  render() {
    return (
      <div className="tabContent">

        {this.state.loading === true &&
          <div>
            <br/>
            <br/>
            <div className="loader"></div>
            <br/>
            <br/>
          </div>
        }

        {this.state.loading === false && this.state.editable === false &&
          <div style={{margin: "20px", marginTop: "40px", marginBottom: "100px", display: "inline"}}>
            <h3>Edit Lottery</h3>
            <p>You can not edit this lottery at this time as it has already begun, or has closed.</p>
          </div>
        }

        {this.state.loading === false && this.state.editable === true &&
        <div style={{margin: "20px", marginTop: "40px", marginBottom: "100px", display: "inline"}}>
          <h3>Edit Lottery</h3>
          <br/><br/>
          <div className="all-items">
            <div className="input-item">
                <p >Name:</p>
                <TextField className="input-item-field" id="name" variant="outlined" required onChange={this.handleStateChange} value={this.state.name}/>
            </div><br/>
            <div className="input-item">
                <p >Description:</p>
                <TextField className="input-item-field" id="description" variant="outlined" multiline rowsMax={6} required onChange={this.handleStateChange} value={this.state.description}/>
            </div>
            <br/>
            <br/>
            <br/>
            <div className="input-item">
              <p >Main Draw Location:</p>
              <TextField className="input-item-field" id="location" variant="outlined" required onChange={this.handleStateChange} value={this.state.location}/>
            </div><br/>
            <div className="input-item">
                <p >Start:</p>
                <TextField
                className="input-item-field"
                variant="outlined"
                id="start"
                type="datetime-local"
                onChange={this.handleStateChange}
                value={this.state.start}
                />
            </div><br/>
            <div className="input-item">
                <p >End:</p>
                <TextField
                className="input-item-field"
                variant="outlined"
                id="end"
                type="datetime-local"
                onChange={this.handleStateChange}
                value={this.state.end}
                />
            </div><br/>
            <div className="input-item">
              <p>Liscence Number:</p>
              <TextField className="input-item-field" id="liscence" variant="outlined" multiline rowsMax={1} required onChange={this.handleStateChange} value={this.state.liscence}/>
            </div><br/>
            <div className="input-item">
                <p >Number of Tickets:</p>
                <TextField className="input-item-field" id="tickets" variant="outlined" type="number" required onChange={this.handleStateChange} value={this.state.tickets}/> 
            </div><br/>
            <div className="input-item">
                <p >Must be 19+:</p>
                <Checkbox
                className="input-item-field-checkbox"
                checked={this.state.over_nineteen}
                onChange={this.handleCheckboxChange}
                id="over_nineteen"
                color="primary"
                />
            </div>
            <br/>
            <p>Note: all customers must be 18+ by default. Only check this option if they must be 19+ to purchase.</p><br/>
            <div className="input-item">
                <p>Main Draw Value:</p>
                <TextField className="input-item-field" id="value" variant="outlined" type="number" required onChange={this.handleStateChange} value={this.state.value}/> 
            </div><br/>
          </div><br/>
          <div className="input-item">
            <p >Has Pre/Post Draw:</p>
              <Checkbox
              className="input-item-field-checkbox"
              checked={this.state.pre_post_draw}
              onChange={this.handleCheckboxChangePost}
              id="pre_post_draw"
              color="primary"
            />
          </div><br/>

          {this.state.pre_post_draw === true &&
            <div>
              <div className="input-item">
                <p >Pre/Post Draw Description:</p>
                <TextField className="input-item-field" id="pre_post_draw_description" variant="outlined" multiline rowsMax={6} required onChange={this.handleStateChange} value={this.state.pre_post_draw_description}/>
              </div><br/>
              <div className="input-item">
                <p >Pre/Post Draw Date:</p>
                <TextField
                  className="input-item-field"
                  variant="outlined"
                  id="pre_post_draw_date"
                  type="datetime-local"
                  onChange={this.handleStateChange}
                  value={this.state.pre_post_draw_date}
                />
            </div><br/>
            <div className="input-item">
              <p >Pre/Post Draw Location:</p>
              <TextField className="input-item-field" id="pre_post_draw_location" variant="outlined" required onChange={this.handleStateChange} value={this.state.pre_post_draw_location}/>
            </div> <br/>
            <div className="input-item">
                <p>Pre/Post Draw Value:</p>
                <TextField className="input-item-field" id="pre_post_draw_value" variant="outlined" type="number" required onChange={this.handleStateChange} value={this.state.pre_post_draw_value}/> 
            </div><br/>
          </div>
          }
          <br/><br/>
          <button className="cancel-button" onClick={this.cancelEdit}>Cancel</button>
          <button className="raffle-button" onClick={this.updateRaffle}>Submit</button>
          <br/>
          <br/>
          </div>
          }
      </div>
    );
  }
}

async function getRaffleDetails(raffleID) {
    let myJson = {};
    myJson.id = raffleID;
    let data = {};
  
    await (async () => {
      const rawResponse = await fetch('/api/v1/draws/get_details', {
        method: 'post',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(myJson)
      });
      data = await rawResponse.json();
    })();
  
    //console.log(data);
  
    return data;
  }

async function updateRaffleData(myJson) {
  let data = {};

  await (async () => {
    const rawResponse = await fetch('/api/v1/draws/update', {
      method: 'post',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(myJson)
    });
    data = await rawResponse.json();
  })();

  //console.log(data);

  return data;
}


async function checkAuth(username, token) {
    let myJson = {};
    myJson.username = username;
    myJson.token = token;
    let data = {};
  
    await (async () => {
      const rawResponse = await fetch('/api/v1/user/auth', {
        method: 'post',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(myJson)
      });
      data = await rawResponse.json();
    })();
  
    //console.log(data);
  
    return data;
  }

  async function getDrawDateDetails(raffleID) {
    let myJson = {};
    myJson.id = raffleID;
    let data = {};
  
    await (async () => {
      const rawResponse = await fetch('/api/v1/draws/check_date', {
        method: 'post',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(myJson)
      });
      data = await rawResponse.json();
    })();
  
    //console.log(data);
  
    return data;
  }

  async function getDrawEndDateDetails(raffleID) {
    let myJson = {};
    myJson.id = raffleID;
    let data = {};
  
    await (async () => {
      const rawResponse = await fetch('/api/v1/draws/check_end_date', {
        method: 'post',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(myJson)
      });
      data = await rawResponse.json();
    })();
  
    //console.log(data);
  
    return data;
  }
  
  // loads the date object from the savedDate cookie
  // returns the date as a string or null if it does not exist
  // can be converted back to date with new Date(dateString)
  function getCookieData() {
    const cookieObj = Object.fromEntries(
      document.cookie.split("; ").map(c => {
        const [key, ...v] = c.split("=");
        return [key, v.join("=")];
      })
    );
    return cookieObj || [];
  }

export default EditRaffleForm;