import React from 'react';
import { Link } from "react-router-dom";
import Modal from 'react-modal';
import history from './history';
import axios from 'axios';

const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)'
    }
  };

class RaffleDetails extends React.Component {
  constructor(props) {
    super(props);

    let pathname = props.location.pathname;
    let id = pathname.split("/")[2];

    this.state = {
      id: id,
      name: "",
      description: "", 
      start: "",
      end: "",
      tickets: 0,
      over_nineteen: 0,
      ticket_price: 0.0,
      full_ticket_price: 0.0,
      value: 0,
      reserved_tickets: 0,
      location: "",
      pre_post_draw: false,
      pre_post_draw_date: "",
      pre_post_draw_value: 0.0,
      pre_post_draw_description: "",
      pre_post_draw_location: "",
      loading: true,
      dateValid: false,
      amount: 0,
      modalIsOpen: false,
    }

    this.getRaffleData = this.getRaffleData.bind(this);
    this.convertDate = this.convertDate.bind(this);
    this.checkIP = this.checkIP.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  componentDidMount () {
    window.scrollTo(0, 0);
    this.getRaffleData();
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  async checkIP(raffleID) {
    try {
      let res = await getIPDetails();
      if(res.region !== "Ontario" && res.country_code !== "CA") {
        this.setState({ modalIsOpen: true });
      }
      else {
        await history.push("/purchase/" + raffleID);
        window.scrollTo(0, 0);
        window.location.reload();
      }
    }
    catch(err) {
      console.log(err);
      this.setState({ modalIsOpen: true });
    }
  }

  async getRaffleData() {
    let dateData = await getDrawDateDetails(this.state.id);

    if(dateData.length > 0) {
      this.setState({dateValid: true})
      let myRaffles = await getRaffleDetails(this.state.id);
      if(myRaffles.length > 0) {
          this.setState({name: myRaffles[0].name});
          this.setState({description: myRaffles[0].description});
          this.setState({start: myRaffles[0].start});
          this.setState({end: myRaffles[0].end});
          this.setState({tickets: myRaffles[0].tickets});
          this.setState({value: myRaffles[0].value});
          this.setState({ticket_price: myRaffles[0].ticket_price});
          this.setState({full_ticket_price: myRaffles[0].full_ticket_price});
          this.setState({over_nineteen: myRaffles[0].over_nineteen});
          this.setState({reserved_tickets: myRaffles[0].reserved_tickets});
          this.setState({location: myRaffles[0].location});
          this.setState({amount: myRaffles[0].amount});

          this.setState({pre_post_draw: myRaffles[0].pre_post_draw});
          this.setState({pre_post_draw_date: myRaffles[0].pre_post_draw_date});
          this.setState({pre_post_draw_description: myRaffles[0].pre_post_draw_description});
          this.setState({pre_post_draw_location: myRaffles[0].pre_post_draw_location});
          this.setState({pre_post_draw_value: myRaffles[0].pre_post_draw_value});
      }
    }

    this.setState({loading: false})
  }

  convertDate(oldDate) {
    let newDate = new Date(oldDate)
    if(newDate) {
      return  newDate.toLocaleString('default', { dateStyle: 'full' });
    }
    else {
      return "";
    }
  }

  render() {
    return (
      <div>
        <Modal
          isOpen={this.state.modalIsOpen}
          onRequestClose={this.closeModal}
          style={customStyles}
          contentLabel="Example Modal"
          ariaHideApp={false}
        >
          <div style={{ textAlign: "center" }}>
            <h1 style={{ fontSize: "40px" }}>Error</h1>
            <p >Due to Ontario lottery rules we need to confirm you are purchasing the ticket within Ontario. You are either not in Ontario, or your device privacy settings are blocking our location check. Adjust your device settings if you are on a cell phone or tablet, try purchasing from a computer or laptop, or contact a St. Marys Rotarian club member to purchase a ticket. Thank You for supporting the St. Marys Rotary club!</p>
            <button onClick={this.closeModal}>Okay</button>
          </div>
        </Modal>
        <div className="tabContent">
          <h3>{this.state.name}</h3>
          <br/>
          <br/>

          {this.state.loading === true &&
            <div>
              <br/>
              <br/>
              <div className="loader"></div>
              <br/>
              <br/>
            </div>
          }

          { this.state.loading === false && this.state.dateValid === false &&
            <p>You can not purchase tickets for this lottery at this time.</p>
          }

        {this.state.loading === false && this.state.dateValid === true &&
          <div>
                <p>{this.state.description}</p>
                <p>Open from {this.convertDate(this.state.start)} to {this.convertDate(this.state.end)}</p>
                <p>Draw location: {this.state.location}</p>
                <p>Draw value: ${this.state.value.toFixed(2)}</p>
                <p>Ticket Price: ${(this.state.ticket_price).toFixed(2)}</p>
                <br/>

                {this.state.pre_post_draw === true &&
                  <div>
                    <p>{this.state.pre_post_draw_description}</p>
                    <p>Bonus draw on {this.convertDate(this.state.pre_post_draw_date)}</p>
                    <p>Bonus draw location: {this.state.pre_post_draw_location}</p>
                    <p>Bonus draw value: ${this.state.pre_post_draw_value.toFixed(2)}</p>
                    <br/>
                  </div>
                }

                {this.state.amount > 1 &&
                  <div>
                    <div className="tickets-banner">
                      <p style={{color: "white", marginTop: "12px"}}>{this.state.amount} Tickets Remaining</p>
                    </div>
                    <br/><br/>
                    <button className="raffle-button" onClick={() => this.checkIP(this.state.id)}>Purchase</button>
                  </div>
                }

                {this.state.amount === 1 &&
                  <div>
                    <div className="tickets-banner">
                      <p style={{color: "white", marginTop: "12px"}}>{this.state.amount} Ticket Remaining</p>
                    </div>
                    <br/><br/>
                    <Link 
                        className="raffle-button" 
                        to={"/purchase/" + this.state.id}>
                          Purchase
                    </Link>
                  </div>
                }

                {this.state.amount <= 0 &&
                    <div>
                      <div className="sold-out-banner">
                        <p style={{color: "white", marginTop: "12px"}}>SOLD OUT</p>
                      </div>
                      <br/><br/>
                      <button className="raffle-button-disabled">Purchase</button>
                    </div>
                }

                <br/>
                <br/>

                {this.state.over_nineteen === 1 &&
                  <p style={{fontSize: "14px"}}>You must be 19+ years of age and live in the province of Ontario to enter this draw</p>
                }

                {this.state.over_nineteen === 0 &&
                  <p style={{fontSize: "14px"}}>You must be 18+ years of age and live in the province of Ontario to enter this draw</p>
                }

            </div>
        

        }

        </div>
        <br/>
        <br/>
      </div>
    );
  }
}

async function getIPDetails() {
  const res = await axios.get('https://api.ipify.org?format=json')
  console.log(res.data.ip);

  const ipData = await axios.get('https://ipapi.co/' + res.data.ip + '/json/');
  console.log(ipData.data)
  return ipData.data;
}

async function getDrawDateDetails(raffleID) {
  let myJson = {};
  myJson.id = raffleID;
  let data = {};

  await (async () => {
    const rawResponse = await fetch('/api/v1/draws/check_date', {
      method: 'post',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(myJson)
    });
    data = await rawResponse.json();
  })();

  //console.log(data);

  return data;
}

async function getRaffleDetails(raffleID) {
  let myJson = {};
  myJson.id = raffleID;
  let data = {};

  await (async () => {
    const rawResponse = await fetch('/api/v1/draws/get_details', {
      method: 'post',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(myJson)
    });
    data = await rawResponse.json();
  })();

  //console.log(data);

  return data;
}

export default RaffleDetails;