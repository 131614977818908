import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import history from './history';
import { Link } from "react-router-dom";
import Tooltip from '@material-ui/core/Tooltip';

class AdminHome extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      raffles: [],
      loading: true
    }

    this.getRaffleData = this.getRaffleData.bind(this);
    this.parsedData = this.parsedData.bind(this);
    this.convertDate = this.convertDate.bind(this);
  }

  componentDidMount(){
    this.getRaffleData();
    window.scrollTo(0, 0);
  }

  async getRaffleData() {
    let authData = getCookieData();
    //console.log(authData);

    if(authData.username && authData.token) { 
      let authCheck = checkAuth(authData.username, authData.token);

      if(authCheck.length === 0) {
        await history.push("/login");
        window.scrollTo(0, 0);
        window.location.reload();
        this.props.changeRemoveAuth();
      }
      else {
        let myRaffles = await getRaffles();
        this.setState({raffles: myRaffles, loading: false});
        this.props.changeAuth();
      }
    }
    else {
      await history.push("/login");
      window.scrollTo(0, 0);
      window.location.reload();
      this.props.changeRemoveAuth();
    }
  }

  convertDate(oldDate) {
    let newDate = new Date(oldDate)
    if(newDate) {
      return  newDate.toLocaleString('default', { dateStyle: 'full' });
    }
    else {
      return "";
    }
  }

  parsedData() {
    return (
      <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
        >
        {this.state.raffles.map((raffle, index) => (
          <Card key={raffle.id} variant="outlined" style={{width: "400px", height: "500px", margin: "10px", border: '3px solid #e1e1e1', borderRadius: "10px"}} >
            <CardContent>
              <h3 className="raffle_title">{raffle.name}</h3>
              <div style={{borderBottom: "2px solid #005daa", width: "380px", marginLeft: "-6px"}} />
              <br/>
              <div style={{height: "250px"}}>
                <p style={{fontSize: "14px"}}>{this.convertDate(raffle.start)}<br/>to<br/>{this.convertDate(raffle.end)}</p>
                <p className="raffle_card_description">{raffle.description}</p>
              </div>
                <Link 
                  className="raffle-button" 
                  to={"/adminlottery/" + raffle.id}>
                    View Details
                </Link>
              </CardContent>
            </Card>
        ))}
     </Grid>
    );
  }

  render() {
    return (
      <div>
        <div className="tabContent">
          <div style={{margin: "20px", marginTop: "10px", marginBottom: "10px"}}>
            <div style={{float: "right"}}>
              <Tooltip title="Add Lottery">
                <Link to="/addlottery">
                      <Icon className="add-button-icon" style={{ fontSize: 40 }}>add_circle</Icon>
                </Link>
              </Tooltip>
            </div>

            <br/>
            <h3>Rotary Municipal Lotteries</h3>
            <br/><br/>
            {this.state.loading === true &&
              <div>
                <br/>
                <br/>
                <div className="loader"></div>
                <br/>
                <br/>
              </div>
            }

            {this.state.raffles.length === 0 && this.state.loading === false &&
              <div>
                <p>No lotteries have been made yet. Click the Add button to create a lottery.</p>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
              </div>
            }

            {this.state.raffles.length > 0 && this.state.loading === false &&
              <div>
                <p>Scroll down to see more lotteries.</p>
                {this.parsedData()}
              </div>
            }
            </div>
        </div>
      </div>
    );
  }
}

async function getRaffles() {
  let data = {};

  await (async () => {
    const rawResponse = await fetch('/api/v1/draws/get_all', {
      method: 'get'
    });
    data = await rawResponse.json();
  })();

  //console.log(data);

  return data;
}

async function checkAuth(username, token) {
  let myJson = {};
  myJson.username = username;
  myJson.token = token;
  let data = {};

  await (async () => {
    const rawResponse = await fetch('/api/v1/user/auth', {
      method: 'post',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(myJson)
    });
    data = await rawResponse.json();
  })();

  //console.log(data);

  return data;
}

// loads the date object from the savedDate cookie
// returns the date as a string or null if it does not exist
// can be converted back to date with new Date(dateString)
function getCookieData() {
  const cookieObj = Object.fromEntries(
    document.cookie.split("; ").map(c => {
      const [key, ...v] = c.split("=");
      return [key, v.join("=")];
    })
  );
  return cookieObj || [];
}

export default AdminHome;