import React from 'react';
import TextField from '@material-ui/core/TextField';
import history from './history';

class EditTicketForm extends React.Component {
  constructor(props) {
    super(props);

    let pathname = props.location.pathname;
    let id = pathname.split("/")[2];

    this.state = {
        id: id,
        raffleID: "",
        customerName: "",
        customerLastname: "",
        customerEmail: "",
        addressLineOne: "",
        addressLineTwo: "",
        postalCode: "",
        city: "",
        customerPhone: "",
        poBox: "",
        province: "",
        country: "",
        errorLabel: "",
        valid: false,
    }

    this.handleStateChange = this.handleStateChange.bind(this);
    this.getTicketData = this.getTicketData.bind(this);
    this.cancelEdit = this.cancelEdit.bind(this);
    this.updateTicket = this.updateTicket.bind(this);
    this.validateInputs = this.validateInputs.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.getTicketData();
  }

  async getTicketData() {
    let authData = getCookieData();
    //console.log(authData);

    if(authData.username && authData.token) { 
      let authCheck = checkAuth(authData.username, authData.token);

      if(authCheck.length === 0) {
        await history.push("/login");
        window.scrollTo(0, 0);
        window.location.reload();
        this.props.changeRemoveAuth();
      }
      else {
        let myTickets = await getTicketDetails(this.state.id);
        if(myTickets.length > 0) {
            this.setState({raffleID: myTickets[0].draw_id});
            this.setState({customerName: myTickets[0].first_name});
            this.setState({customerLastname: myTickets[0].last_name});
            this.setState({customerEmail: myTickets[0]}.email);
            this.setState({customerPhone: myTickets[0].phone});
            this.setState({addressLineOne: myTickets[0].address_line_one});
            this.setState({addressLineTwo: myTickets[0].address_line_two});
            this.setState({postalCode: myTickets[0].postal_code});
            this.setState({city: myTickets[0].city});
            this.setState({poBox: myTickets[0].po_box});
            this.setState({province: myTickets[0].province});
            this.setState({country: myTickets[0].country});
        }

        this.props.changeAuth();
        this.setState({loading: false})
      }
    }
    else {
      await history.push("/login");
      window.scrollTo(0, 0);
      window.location.reload();
      this.props.changeRemoveAuth();
    }
  }

  handleStateChange = async(e) => {
    await this.setState({ [e.target.id]: e.target.value });
    this.validateInputs();
  }

  async cancelEdit() {
    await history.push("/adminlottery/" + this.state.raffleID);
    window.scrollTo(0, 0);
    window.location.reload();
  }

  async updateTicket() {
    if(this.validateInputs) {
      let myJson = {};
      myJson.id = this.state.id;
      myJson.first_name = this.state.customerName;
      myJson.last_name = this.state.customerLastname;
      myJson.email = this.state.customerEmail;
      myJson.phone = this.state.customerPhone;
      myJson.address_line_one = this.state.addressLineOne;
      myJson.address_line_two = this.state.addressLineTwo;
      myJson.postal_code = this.state.postalCode;
      myJson.city = this.state.city;
      myJson.po_box = this.state.poBox;
      myJson.province = this.state.province;
      myJson.country = this.state.country;

      let response = await updateTicketData(myJson);
      console.log(response);

      if(response.affectedRows !== undefined && response.affectedRows !== 0) {
        this.setState({loading: false});
          await history.push("/adminlottery/" + this.state.raffleID);
          window.scrollTo(0, 0);
          window.location.reload();
      }
      else {
        this.setState({loading: false});
          //console.log(response.error);
      }
    }
    else {
        //console.log("Invalid input");
    }
  }

  validateInputs() {
    if(!(this.state.customerEmail.includes("@")) && this.state.customerEmail !== "") {
      this.setState({errorLabel: "You must enter a valid email address"})
      this.setState({valid: false})
      return false;
    }
    else if(!(this.state.customerEmail.includes(".")) && this.state.customerEmail !== "") {
      this.setState({errorLabel: "You must enter a valid email address"})
      this.setState({valid: false})
      return false;
    }
    else if(this.state.postalCode.length !== 7 && this.state.postalCode.length !== 0) {
      this.setState({errorLabel: "Postal code must be in the format A1A 1A1"})
      this.setState({valid: false})
      return false;
    }
    else if((this.state.postalCode.length !== 0 && !(this.state.postalCode[1] >= '0' && this.state.postalCode[1] <= '9')) || 
    !(this.state.postalCode[4] >= '0' && this.state.postalCode[4] <= '9') ||
    !(this.state.postalCode[6] >= '0' && this.state.postalCode[6] <= '9')) {
      this.setState({errorLabel: "Postal code must be in the format A1A 1A1"})
      this.setState({valid: false})
      return false;
    }
    else if((this.state.postalCode.length !== 0 && !(this.state.postalCode[0].match(/[a-z]/i))) ||
    !(this.state.postalCode[2].match(/[a-z]/i)) ||
    !(this.state.postalCode[5].match(/[a-z]/i))) {
      this.setState({errorLabel: "Postal code must be in the format A1A 1A1"})
      this.setState({valid: false})
      return false;
    }
    else if(this.state.postalCode.length !== 0 && this.state.postalCode[3] !== ' ') {
      this.setState({errorLabel: "Postal code must be in the format A1A 1A1"})
      this.setState({valid: false})
      return false;
    }

    this.setState({errorLabel: ""})
    this.setState({valid: true})

    return true;
  }

  render() {
    return (
      <div className="tabContent">
        <h3>Edit Ticket</h3>
        <br/>
          <div>
            <div className="input-item">
              <p>Firstname:</p>
              <TextField className="input-item-field" id="customerName" variant="outlined" required onChange={this.handleStateChange} value={this.state.customerName}/> 
            </div><br/>

            <div className="input-item">
              <p>Lastname:</p>
              <TextField className="input-item-field" id="customerLastname" variant="outlined" required onChange={this.handleStateChange} value={this.state.customerLastname}/> 
            </div><br/>

            <div className="input-item">
              <p>Email:</p>
              <TextField className="input-item-field" id="customerEmail" variant="outlined" required onChange={this.handleStateChange} value={this.state.customerEmail}/> 
            </div><br/>
            
            <div className="input-item">
              <p>Phone:</p>
              <TextField className="input-item-field" id="customerPhone" variant="outlined" required onChange={this.handleStateChange} value={this.state.customerPhone}/> 
            </div><br/><br/>

            <div className="input-item">
            <p>PO Box:</p>
              <TextField className="input-item-field" id="poBox" variant="outlined" required onChange={this.handleStateChange} value={this.state.poBox}/> 
            </div><br/>
            
            <div className="input-item">
            <p>Address Line 1:</p>
              <TextField className="input-item-field" id="addressLineOne" variant="outlined" required onChange={this.handleStateChange} value={this.state.addressLineOne}/> 
            </div><br/>

            <div className="input-item">
            <p>Address Line 2:</p>
              <TextField className="input-item-field" id="addressLineTwo" variant="outlined" onChange={this.handleStateChange} value={this.state.addressLineTwo}/> 
            </div><br/>

            <div className="input-item">
            <p>Postal Code:</p>
              <TextField className="input-item-field" id="postalCode" variant="outlined" onChange={this.handleStateChange} value={this.state.postalCode}/> 
            </div><br/>

            <div className="input-item">
            <p>City:</p>
              <TextField className="input-item-field" id="city" variant="outlined" onChange={this.handleStateChange} value={this.state.city}/> 
            </div><br/>

            <div className="input-item">
            <p>Province:</p>
              <TextField className="input-item-field" id="province" variant="outlined" onChange={this.handleStateChange} value={this.state.province}/> 
            </div><br/>

            <div className="input-item">
            <p>Country:</p>
              <TextField className="input-item-field" id="country" variant="outlined" onChange={this.handleStateChange} value={this.state.country}/> 
            </div><br/><br/>

            <button className="cancel-button" onClick={this.cancelEdit}>Cancel</button>
            { this.state.valid === true &&
              <button className="raffle-button" onClick={this.updateTicket}>Submit</button>
            }

            { this.state.valid === false &&
              <button className="raffle-button-disabled">Submit</button>
            }
            <br/><br/><br/>
            <p className="errorLabel" >{ this.state.errorLabel }</p>
            <br/>
          </div>
      </div>
    );
  }
}

async function getTicketDetails(ticketID) {
  let myJson = {};
  myJson.id = ticketID;
  let data = {};

  await (async () => {
    const rawResponse = await fetch('/api/v1/tickets/get_details', {
      method: 'post',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(myJson)
    });
    data = await rawResponse.json();
  })();

  //console.log(data);

  return data;
}


async function updateTicketData(myJson) {
    let data = {};
  
    await (async () => {
      const rawResponse = await fetch('/api/v1/tickets/update', {
        method: 'post',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(myJson)
      });
      data = await rawResponse.json();
    })();
  
    //console.log(data);
  
    return data;
  }
  
  
  async function checkAuth(username, token) {
      let myJson = {};
      myJson.username = username;
      myJson.token = token;
      let data = {};
    
      await (async () => {
        const rawResponse = await fetch('/api/v1/user/auth', {
          method: 'post',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(myJson)
        });
        data = await rawResponse.json();
      })();
    
      //console.log(data);
    
      return data;
    }
    
    // loads the date object from the savedDate cookie
    // returns the date as a string or null if it does not exist
    // can be converted back to date with new Date(dateString)
    function getCookieData() {
      const cookieObj = Object.fromEntries(
        document.cookie.split("; ").map(c => {
          const [key, ...v] = c.split("=");
          return [key, v.join("=")];
        })
      );
      return cookieObj || [];
    }

export default EditTicketForm;