import React from 'react';
import { Link } from "react-router-dom";
import MediaQuery  from 'react-responsive';
import CheeseburgerMenu from 'cheeseburger-menu';
import HamburgerMenu from 'react-hamburger-menu';
import logo from './Images/logo.png';
import history from './history';


const mobileOptions = {
  backgroundColor: "white",
  color: 'black', 
  textAlign: 'center',
  fontSize: '24px',
  paddingTop: "10px",
  paddingBottom: "10px",
  textDecoration: 'none',
  border: "1px solid grey",
}

class Navbar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      menuOpen: false,
      showMenu: false,
    };

    this.logOut = this.logOut.bind(this);
    this.showMenu = this.showMenu.bind(this); 
    this.closeMenu = this.closeMenu.bind(this);
  }

  showMenu() {
    let visible = this.state.showMenu;
    this.setState({
      showMenu: !visible,
    });
  }

  openMenu() {
    this.setState({ menuOpen: true })
  }

  closeMenu() {
    this.setState({ menuOpen: false })
  }

  async logOut() {
    this.closeMenu();
    let authData = getCookieData();
    //console.log(authData);

    if(authData.username && authData.token) {
      let response = await logOut(authData.username, authData.token);
      this.props.removeAuth();
      await history.push("/login");
      window.scrollTo(0, 0);
      window.location.reload();

      if(response.error !== undefined) {
        //console.log(response.error);
      }
    }
  }

  render() {
    return (
          <div>
              <MediaQuery query='(min-width: 800px)'>
                <nav className="tabHeader">
                <div style={{float:"Right", paddingRight: "20px", marginTop: "-35px"}}>
                  {this.props.auth === false &&
                    <Link 
                      className="inactive-tab-button"
                      style={{fontSize: "22px", color: "black"}}
                      to="/login">
                      Login
                    </Link>
                  }

                  {this.props.auth === true &&
                    <div>
                      <a
                        href="/#"
                        className="inactive-tab-button-login"
                        style={{fontSize: "22px"}}
                        onClick={this.logOut}
                        >
                        Logout
                      </a>
                    </div>
                  }
                </div>
                <img alt="St Marys Rotary Club" src={logo} style={{width: "300px", position: "absolute", left: "20px", top: "-25px"}}/>

                <div style={{float:"Right", paddingRight: "60px", paddingTop: "15px"}}>
                    <Link 
                    className="inactive-tab-button" 
                    to="/">
                    Home
                    </Link>

                  {this.props.auth === true &&
                    <Link 
                    className="inactive-tab-button"
                    to="/admin">
                    Admin
                    </Link>
                  }
                    <Link 
                    className="inactive-tab-button"
                    to="/contact">
                    Contact Us
                    </Link>
              </div> 
            </nav>
          </MediaQuery>

          <MediaQuery query='(max-width: 799px)'>
            <div className="tabHeader">
                <img alt="St Marys Rotary Club" src={logo} style={{width: "200px", height: "80px", position: "absolute", left: "20px", top: "0px", marginTop: "-10px"}}/>

                <CheeseburgerMenu
                  right={true}
                  isOpen={this.state.menuOpen}
                  closeCallback={this.closeMenu.bind(this)}>
                        <Link onClick={this.closeMenu.bind(this)} style={{textDecoration: "none"}} to="/">
                          <p style={{mobileOptions}}>Home</p>
                        </Link>
                        <br/>
                        <Link onClick={this.closeMenu.bind(this)} style={{textDecoration: "none"}} to="/admin">
                          <p style={{mobileOptions}}>Admin</p>
                        </Link>
                        <br/>
                        <Link onClick={this.closeMenu.bind(this)} style={{textDecoration: "none"}} to="/contact">
                          <p style={{mobileOptions}}>Contact Us</p>
                        </Link>
                        <br/>

                      {this.props.auth === false &&
                        <Link onClick={this.closeMenu.bind(this)} style={{textDecoration: "none"}} to="/login">
                        <p style={{mobileOptions}}>Login</p></Link>
                      }
                        <br/>

                      {this.props.auth === true &&
                        <Link onClick={this.logOut} style={{textDecoration: "none"}}>
                        <p style={{mobileOptions}}>Logout</p></Link>
                      }
                </CheeseburgerMenu>
          
                <HamburgerMenu
                  isOpen={this.state.menuOpen}
                  menuClicked={this.openMenu.bind(this)}
                  width={32}
                  height={24}
                  strokeWidth={3}
                  rotate={0}
                  color='black'
                  borderRadius={0}
                  animationDuration={0.5}
                  className="hamburgerMenu"
                />
            </div>
        </MediaQuery>
      </div>
    );
  }
}


async function logOut(username, token) {
  let myJson = {};
  myJson.username = username;
  myJson.token = token;
  let data = {};

  await (async () => {
    const rawResponse = await fetch('/api/v1/user/logout', {
      method: 'post',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(myJson)
    });
    data = await rawResponse.json();
  })();

  //console.log(data);

  return data;
}

function getCookieData() {
  const cookieObj = Object.fromEntries(
    document.cookie.split("; ").map(c => {
      const [key, ...v] = c.split("=");
      return [key, v.join("=")];
    })
  );
  return cookieObj || [];
}

export default Navbar;