import React from 'react';

class ContactForm extends React.Component {
  componentDidMount () {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div>
        <div className="tabContent">
        < div style={{margin: "20px", marginTop: "10px", marginBottom: "10px"}}>
          <h3>Contact Us</h3>
          <br/>
          <br/>
          <p>Email: <a href="mailto:stmarysrotary@hotmail.com">stmarysrotary@hotmail.com</a></p>
          <p>Facebook: <a href="https://www.facebook.com/rotarystmarys/">https://www.facebook.com/rotarystmarys/</a></p>
          <p>Instagram: <a href="https://www.instagram.com/rotarystmarys/?fbclid=IwAR16QSFXnOWSoBYMp091fqiTpEqSg6qd7-7kEt2DRdyIgtzEvOPHLEEHC84">https://www.instagram.com/rotarystmarys/</a></p>
          <p>Main Website: <a href="https://rotarystmarys.ca/">https://rotarystmarys.ca/</a></p>
                <p>The Rotary Club of St. Marys <br/>
                22 St. Andrews St. N.,<br/>
                St. Marys, ON N4X 1C5 <br/><br/>
                Mailing address: 12-20 Huron St. N., <br/> 
                St. Marys, ON N4X 1C5
                </p>
            </div>
        </div>
      </div>
    );
  }
}


export default ContactForm;